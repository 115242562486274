import React from 'react';
import { Popup } from 'devextreme-react/popup';
import { appConst } from '../../AppConst';
import notify from 'devextreme/ui/notify';
import { CreateAuthorizedStore, getAuthorizationTokenAsync } from '../../helpers/data';
import FileManager, { Toolbar, Item, FileSelectionItem, Permissions } from 'devextreme-react/file-manager';
import CustomFileSystemProvider from 'devextreme/file_management/custom_provider';

export function FileExplorer(props) {
  let fileManagerRef = null;
  const { insert, setShowFileExplorer, visible } = props;
  function loadFolders(pathInfo) {
    return new Promise((resolve, reject) => {
      const ds = CreateAuthorizedStore(
        {
          loadUrl: ('' === pathInfo) ?
            `${window.env.apiEndpoint}/api/v1/MailChimpFileExplorer/GetFolders` : `${window.env.apiEndpoint}/api/v1/MailChimpFileExplorer/GetFiles`},
        { load: { folderId: pathInfo } }
        , null, 'Id');
      ds.load().then(
        (data) => {
          resolve(data);
          ds.dispose();
        },
        (error) => {
          reject(error);
        });
    });
  }

  const fileSystemProvider = new CustomFileSystemProvider({
    getItems,
    createDirectory,
    deleteItem,
    uploadFileChunk,
    downloadItems
  });

  async function getItems(pathInfo) {
    const data = await loadFolders(pathInfo.key);
    return data;
  }
  function createDirectory(parentDirectory, name) {
    const ds = CreateAuthorizedStore(
      { insertUrl: `${window.env.apiEndpoint}/api/v1/MailChimpFileExplorer/CreateFolder` },
      { insert: { folderName: name } }
      , 'Id');

    ds.insert().then(
      async (data) => {
        await setTimeout(2000);
        fileManagerRef.instance.refresh();
      },
      (error) => {
        notify({ message: error.message, shading: true }, 'error');
      });
  }
  function deleteItem(item) {
    let ds;
    if (item.isDirectory) {
      ds = CreateAuthorizedStore(
        { deleteUrl: `${window.env.apiEndpoint}/api/v1/MailChimpFileExplorer/DeleteFolder` },
        { delete: { folderId: item.key } }
        , 'Id');
    } else {
      ds = CreateAuthorizedStore(
        { deleteUrl: `${window.env.apiEndpoint}/api/v1/MailChimpFileExplorer/DeleteFile` },
        { delete: { fileId: item.key } }
        , 'Id');
    }

    ds.remove().then(
      (data) => {
        fileManagerRef.instance.refresh();
      },
      (error) => {
        notify({ message: error.message, shading: true }, 'error');
      });
  }

  async function uploadFileChunk(file, uploadInfo, destinationDirectory) {
    const token = await getAuthorizationTokenAsync();
    const params = {
      folderName: destinationDirectory.path
    };

    const url = `${window.env.apiEndpoint}/api/v1/MailChimpFileExplorer/UploadFileInFolder?${new URLSearchParams(params)}`;

    const formData = new FormData();
    formData.append("file", file);

    try {
      const response = await fetch(url, {
        method: "POST",
        body: formData,
        headers: new Headers({
          'Authorization': `Bearer ${token.accessToken}`,
        }),
      });

      if (response.ok) {
      } else {
        notify({ message: await response.text(), shading: true }, 'error');
      }
    } catch (error) {
      console.error("Error uploading file:", error);
    }
  }

  async function downloadFile(url, fileName) {
    const token = await getAuthorizationTokenAsync();
    const params = {
      fileUrl: url
    };

    try {
      const response = await
        fetch(`${window.env.apiEndpoint}/api/v1/MailChimpFileExplorer/DownloadFile?${new URLSearchParams(params)}`,
          {
            method: "GET",
            headers: new Headers({ 'Authorization': `Bearer ${token.accessToken}` }),
          }
        );

      if (!response.ok) {
        throw new Error(`Error: ${response.statusText}`);
      }

      const blob = await response.blob();
      const contentDisposition = response.headers.get("content-disposition");

      // Extract filename from the Content-Disposition header if available
      if (contentDisposition && contentDisposition.includes("filename=")) {
        const matches = contentDisposition.match(/filename="?([^"]+)"?/);
        if (matches?.[1]) {
          fileName = matches[1];
        }
      }

      // Create a link to download the file
      const link = document.createElement("a");
      link.href = URL.createObjectURL(blob);
      link.download = fileName;
      link.style.display = "none";
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
    catch (error) {
      console.error("File download failed:", error);
    }
  }

  async function downloadItems(files) {
    await downloadFile(files[0].dataItem.url, files[0].dataItem.name);
  }

  const fileAttachOptions = {
    items: [
      {
        text: 'INSERT ATTACHMENTS',
        icon: 'fas fa-paperclip',
        onClick: (e) => {
          const selections = fileManagerRef.instance.getSelectedItems();
          selections.forEach((sel) => {
            insert(sel.dataItem.url, 'link', sel.dataItem.name, null);
          });
          setShowFileExplorer(false);
        }
      }
    ],
  };

  const fileImageOptions = {
    items: [
      {
        text: 'EMBED PICTURE',
        icon: 'image',
        onClick: (e) => {
          const selections = fileManagerRef.instance.getSelectedItems();
          selections.forEach((sel) => {
            insert(sel.dataItem.url, 'extendedImage', null, sel.dataItem.name);
          });
          setShowFileExplorer(false);
        }
      }
    ],
  };

  return (
    <Popup
      visible={visible}
      title='File Explorer'
      height='60%'
      width='70%'
      onHiding={
        () => {
          setShowFileExplorer(false);
        }
      }
      onShowing={
        (e) => {
          let height = (e.component._$content[0].clientHeight) * 80/100;
          fileManagerRef.instance.option('height', height);
        }
      }
      >
      <FileManager
        ref={node => { fileManagerRef = node; }}
        fileSystemProvider={fileSystemProvider}
        allowedFileExtensions={appConst.acceptFileToUpload.replace(/\s/g, '').split(',')}
      >
        <Permissions
          create={true}
          delete={true}
          download={true}
          upload={true}
        >
        </Permissions>
        <Toolbar>
          <Item name="create" text="NEW FOLDER" icon="folder" />
          <Item name="upload" text="UPLOAD" icon="upload" />
          <FileSelectionItem name="delete" text="DELETE" icon="remove" />
          <FileSelectionItem widget="dxMenu" options={fileAttachOptions} />
          <FileSelectionItem widget="dxMenu" options={fileImageOptions} />
        </Toolbar>         
        </FileManager>
      </Popup>
  );
}