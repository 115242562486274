import React, { useState } from 'react';
import { Popup } from 'devextreme-react/popup';
import {
  Form as DxForm,
  GroupItem,
  SimpleItem,
  RequiredRule,
  PatternRule,
  Item as FItem,
  EmptyItem,
  Label
} from 'devextreme-react/form';
import {
  HtmlEditor as DxHtmlEditor,
  Toolbar,
  MediaResizing,
  Item,
} from 'devextreme-react/html-editor';
import HtmlEditor from "devextreme/ui/html_editor";
import Button from 'devextreme-react/button';
import Form from "devextreme/ui/form";
import notify from 'devextreme/ui/notify';
import DateBox from 'devextreme-react/date-box';
import CheckBox from 'devextreme-react/check-box';
import TextBox from 'devextreme-react/text-box';
import SelectBox from 'devextreme-react/select-box';
import { LoadPanel } from 'devextreme-react/load-panel';
import { FileUploader } from 'devextreme-react/file-uploader';
import { Explorer } from './explorer'
import { FileExplorer } from './file-explorer'
import { CreateAuthorizedStore, SetAuthorizationHeaderAsync } from '../../helpers/data';
import { appConst } from '../../AppConst';

const sizeValues = ['8pt', '10pt', '12pt', '14pt', '18pt', '24pt', '36pt'];
const fontValues = [
  'Arial',
  'Courier New',
  'Georgia',
  'Impact',
  'Lucida Console',
  'Tahoma',
  'Times New Roman',
  'Verdana',
];
const fontSizeOptions = {
  inputAttr: {
    'aria-label': 'Font size',
  },
};
const fontFamilyOptions = {
  inputAttr: {
    'aria-label': 'Font family',
  },
};
const headerOptions = {
  inputAttr: {
    'aria-label': 'Font family',
  },
};
const headerValues = [false, 1, 2, 3, 4, 5];
const sendOptions = [
  { id: 1, name: 'Now', icon: 'email' },
  { id: 2, name: 'Schdule a time', icon: 'clock' },
]

const mergeFieldsDS = CreateAuthorizedStore({ loadUrl: `${window.env.apiEndpoint}/api/v1/DistributionList/GetMergeFields` }, null, 'Value');
export function EmailClient(props) {
  const [showDateTimeSelector, setShowDateTimeSelector] = useState(false);
  const [showMarkup, setShowMarkup] = useState(false);
  const [showUploadPicture, setShowUploadPicture] = useState(false);
  const [showUploadFiles, setShowUploadFiles] = useState(false);
  const [currentPosition, setCurrentPosition] = useState({ index: 0 });
  const [sentTime, setSentTime] = useState('now');
  const [showLoadingPanel, setShowLoadingPanel] = useState(false);
  const [showExplorer, setShowExplorer] = useState(false);
  const [showFileExplorer, setShowFileExplorer] = useState(false);
  const [showImageProperties, setShowImageProperties] = useState(false);
  const [imagePropertiesFormData, setImagePropertiesFormData] = useState({ index: null });
  const [imageList, setImageList] = useState([]);
  function getHtmEditor() {
    const el = document.getElementById("htmlEditor");
    const component = HtmlEditor.getInstance(el);

    return component;
  }

  function updateHtmlEditorValue(v) {
    let editor = getHtmEditor();
    editor.option('value', v);
  }

  function getImages() {
    var arr = [];
    const el = document.getElementById("htmlEditor");
    const imgElements = el.querySelectorAll('img');
    imgElements.forEach((img, index) => {
      arr.push({ src: img.src, width: img.width, height: img.height, id: index, har: img.height / img.width, war: img.width / img.height });
    });
    setImageList(arr);
    setImagePropertiesFormData({ ...arr[0], index: 0 });
  }

  const renderItem = (data) => {
    return (
      <div>
        <img src={data && data.src} width='50px' height='50px' alt=''/>
        <div style={{ display: 'inline-block', marginLeft:'10px' }}>{data.src}</div>
      </div>
    );
  }

  function insert(url, type, fileName, altText, width, height) {
    const editor = getHtmEditor();

    editor.insertEmbed(currentPosition.index, type,
      {
        src: url,
        href: url,
        text: fileName,
        alt: altText,
        width: width,
        height: height,
      });
    if ('link' === type) {
      editor.insertText(currentPosition.index + fileName.length, '\n');
      editor.insertText(currentPosition.index + fileName.length, '\t');
    }
  }

  const fieldRender = (data) => {
    return (
      <div style={{ width: '100%' }}>
        <img src={data && data.src} width='50px' height='50px' alt=''/>
        <TextBox
          value={data && data.src}
          readOnly={true}
          style={{ display: 'inline-block', marginLeft: '10px' }}
          width='100%'
        />
      </div>
    );
  }
  const { visible, setViewEmailClient, formData } = props;
  return (
    <>
      <Popup
        id="emailClientPopup"
        visible={visible}
        title='New Email...'
        height="80%"
        onHiding={
          () => {
            setViewEmailClient(false);
          }
        }
        onShowing={
          (e) => {
            let height = (e.component._$content[0].clientHeight) * 55 / 100;
            getHtmEditor().option('height', height);
          }
        }
      >
        <LoadPanel
          shadingColor='rgba(0,0,0,0.4)'
          visible={showLoadingPanel}
        />
        <DxForm
          id='emailForm'
          labelLocation='left'
          formData={formData}
        >
          <GroupItem colCount={2}>
            <SimpleItem
              colSpan={2}
              dataField='to'
              editorType='dxTextBox'
              editorOptions={{ disabled: true }}
            >
              <RequiredRule message='From email is required' />
            </SimpleItem>
            <SimpleItem
              colSpan={2}
              dataField='subject'
              editorType='dxTextBox'
            >
              <RequiredRule message='Subject is required' />
            </SimpleItem>
            <SimpleItem
              dataField='email'
              editorType='dxTextBox'
            >
              <RequiredRule message='From email is required' />
              <PatternRule
                pattern={appConst.emailPattern}
                message={appConst.emailValidationMessage}
              />
            </SimpleItem>
            <SimpleItem
              dataField='name'
              editorType='dxTextBox'
            >
              <RequiredRule message='From text is required' />
            </SimpleItem>
            <Item
              colSpan={2}
            >
              <DxHtmlEditor
                id='htmlEditor'
                onInitialized={
                  (e) => {
                    e.component.option('tableResizing', { enabled: true });
                  }
                }
              >
                <MediaResizing enabled={true} />
                <Toolbar multiline={true}>
                  <Item name='undo' />
                  <Item name='redo' />
                  <Item name='separator' />
                  <Item
                    name='size'
                    acceptedValues={sizeValues}
                    options={fontSizeOptions}
                  />
                  <Item
                    name='font'
                    acceptedValues={fontValues}
                    options={fontFamilyOptions}
                  />
                  <Item name='separator' />
                  <Item name='bold' />
                  <Item name='italic' />
                  <Item name='strike' />
                  <Item name='underline' />
                  <Item name='separator' />
                  <Item name='alignLeft' />
                  <Item name='alignCenter' />
                  <Item name='alignRight' />
                  <Item name='alignJustify' />
                  <Item name='separator' />
                  <Item name='orderedList' />
                  <Item name='bulletList' />
                  <Item name='separator' />
                  <Item
                    name='header'
                    acceptedValues={headerValues}
                    options={headerOptions}
                  />
                  <Item name='separator' />
                  <Item name='color' />
                  <Item name='background' />
                  <Item name='separator' />
                  <Item name='link' />
                  <Item widget='dxButton'
                    options={{
                      icon: 'fas fa-paperclip',
                      hint: 'Attach files',
                      stylingMode: 'icon',
                      onClick: () => {
                        setCurrentPosition(getHtmEditor().getSelection(true));
                        setShowUploadFiles(true);
                      }
                    }} />
                  <Item name='image' />
                  <Item widget='dxButton'
                    options={{
                      icon: 'palette',
                      hint: 'Change Images Properties... ',
                      stylingMode: 'icon',
                      onClick: () => {
                        getImages();
                        setShowImageProperties(true);
                      }
                    }} />
                  <Item widget='dxButton'
                    options={{
                      icon: 'upload',
                      hint: 'Upload image and embed',
                      stylingMode: 'icon',
                      onClick: () => {
                        setCurrentPosition(getHtmEditor().getSelection(true));
                        setShowUploadPicture(true);
                      }
                    }} />
                  <Item name='separator' />
                  <Item name='clear' />
                  <Item name='codeBlock' />
                  <Item name='blockquote' />
                  <Item name='separator' />
                  <Item name='insertTable' />
                  <Item name='deleteTable' />
                  <Item name='insertRowAbove' />
                  <Item name='insertRowBelow' />
                  <Item name='deleteRow' />
                  <Item name='insertColumnLeft' />
                  <Item name='insertColumnRight' />
                  <Item name='deleteColumn' />
                  <Item name='separator' />
                  <Item widget='dxSelectBox'
                    options={{
                      dataSource: mergeFieldsDS,
                      displayExpr: 'Text',
                      valueExpr: 'Value',
                      placeholder: 'Insert Merge Field..',
                      onValueChanged(data, e) {
                        const editor = getHtmEditor();
                        const newPosition = editor.getSelection(true);
                        editor.insertText(newPosition.index, ` *|${data.value}|* `);
                      }
                    }}
                  />
                  <Item name='separator' />
                  <Item widget='dxButton'
                    options={{
                      icon: 'activefolder',
                      hint: 'Open Saved Emails...',
                      stylingMode: 'icon',
                      onClick: () => {
                        setShowExplorer(true);
                      }
                    }} />
                  <Item name='separator' />
                  <Item widget='dxButton'
                    options={{
                      icon: 'find',
                      hint: 'Open File Explorer...',
                      stylingMode: 'icon',
                      onClick: () => {
                        setCurrentPosition(getHtmEditor().getSelection(true));
                        setShowFileExplorer(true);
                      }
                    }} />
                </Toolbar>
              </DxHtmlEditor>
            </Item>
          </GroupItem>
          <GroupItem showCaption={false} colCount={10}>
            <EmptyItem colSpan={6} />
            <FItem>
              <Button
                text='Markup'
                width='100%'
                onClick={
                  (e) => {
                    setShowMarkup(true);
                  }
                }
              />
            </FItem>
            <FItem>
              <Button
                text='Send...'
                items={sendOptions}
                width='100%'
                onClick={
                  (e) => {
                    const form = Form.getInstance(document.getElementById('emailForm'));
                    const v = form.validate();
                    if (!v.isValid) return false;

                    setShowDateTimeSelector(true);
                  }
                }
              />
            </FItem>
            <FItem>
              <Button
                text='Draft'
                width='100%'
                onClick={
                  (e) => {
                    const form = Form.getInstance(document.getElementById('emailForm'));
                    const formData = form.option('formData');
                    const v = form.validate();
                    if (!v.isValid) return false;

                    setShowLoadingPanel(true);
                    const ds = CreateAuthorizedStore(
                      { updateUrl: `${window.env.apiEndpoint}/api/v1/DistributionList/CreateAndSaveDraft` },
                      { update: { name: formData.name, email: formData.email, to: formData.to, subject: formData.subject, body: getHtmEditor().option('value') } },
                      'Value');

                    ds.update().then(
                      (data) => {
                        setShowLoadingPanel(false);
                        setViewEmailClient(false);
                        notify({ message: 'Email has been created and saved to draft', shading: true }, 'success');
                        setShowDateTimeSelector(false);
                        getHtmEditor().option('value', null);
                      },
                      (error) => {
                        setShowLoadingPanel(false);
                        notify({ message: error.message, shading: true }, 'error');
                      }
                    );
                  }
                }
              />
            </FItem>
            <FItem>
              <Button
                text='Cancel'
                width='100%'
                onClick={
                  (e) => {
                    getHtmEditor().option('value', null);
                    setViewEmailClient(false);
                  }
                }
              />
            </FItem>
          </GroupItem>
        </DxForm>
      </Popup>
      <Popup
        visible={showDateTimeSelector}
        showTitle={true}
        height='20%'
        width='20%'
        title='Set Sent Time...'
        onHiding={
          () => {
            setShowDateTimeSelector(false);
          }
        }
      >
        <LoadPanel
          shadingColor='rgba(0,0,0,0.4)'
          visible={showLoadingPanel}
        />
        <DateBox
          type='datetime'
          disabled={(sentTime === 'now')}
          dateSerializationFormat='yyyy-MM-dd HH:mm'
          displayFormat={appConst.dateAndTimeDisplayFormat}
          onValueChanged={
            (e) => {
              setSentTime(e.value);
            }
          }
        />
        <div style={{ marginTop: '10px' }}>
          <CheckBox
            text='Now'
            defaultValue={true}
            onValueChanged={(e) => {
              if (e.value) {
                setSentTime('now');
              } else {
                setSentTime(null);
              }
            }}
          />
        </div>
        <div style={{ display: 'flex', gap: '10px', marginTop: '10px' }}>
          <Button
            text='Send'
            width='50%'
            onClick={
              (e) => {
                setShowLoadingPanel(true);
                const form = Form.getInstance(document.getElementById('emailForm'));
                const formData = form.option('formData');

                const ds = CreateAuthorizedStore(
                  { updateUrl: `${window.env.apiEndpoint}/api/v1/DistributionList/CreateAndSendCampaign` },
                  { update: { email: formData.email, name: formData.name, to: formData.to, subject: formData.subject, body: getHtmEditor().option('value'), sendTime: sentTime } },
                  'Value');

                ds.update().then(
                  (data) => {
                    setShowLoadingPanel(false);
                    setShowDateTimeSelector(false);
                    setViewEmailClient(false);
                    notify({ message: 'Campaign has been created', shading: true }, 'success');
                    getHtmEditor().option('value', null);
                  },
                  (error) => {
                    setShowLoadingPanel(false);
                    notify({ message: error.message, shading: true }, 'error');
                  }
                );
              }
            }
          />
          <Button
            text='Cancel'
            width='50%'
            onClick={
              (e) => {
                setShowDateTimeSelector(false);
              }
            }
          />
        </div>
      </Popup>
      <Popup
        visible={showMarkup}
        showTitle={true}
        title='Markup'
        onHiding={() => {
          setShowMarkup(false);
        }}
        showCloseButton={true}
        resizeEnabled={true}
      >
        <div style={{ overflowY: 'scroll', height: '90%' }}>{getHtmEditor() && getHtmEditor().option('value')}</div>
      </Popup>
      {showUploadFiles && <Popup
        visible={showUploadFiles}
        showTitle={true}
        title='Attach Files...'
        onHiding={() => {
          setShowUploadFiles(false);
        }}
        width='40%'
        height='40%'
      >
        <FileUploader
          id='fileUploader'
          name='file'
          multiple={true}
          accept={appConst.acceptFileToUpload}
          uploadUrl={`${window.env.apiEndpoint}/api/v1/DistributionList/UploadFile`}
          uploadMode='instantly'
          onUploaded={(e) => {
            const ob = JSON.parse(e.request.responseText);

            insert(ob.url, 'link', ob.fileName);
            setShowUploadPicture(false);
          }}
          onFilesUploaded={(e) => {
            e.component.reset();
            setShowUploadFiles(false);
          }}
          onInitialized={async (e) => {
            const o = {};
            await SetAuthorizationHeaderAsync(o);
            e.component.option('uploadHeaders', o.headers);
          }}
          onUploadError={(e) => {
            notify({ message: e.request.responseText, shading: true }, 'error');
            e.request.abort();
            e.component.reset();
          }}
        />
      </Popup>}
      {showUploadPicture && <Popup
        visible={showUploadPicture}
        showTitle={true}
        title='Upload Picture...'
        onHiding={() => {
          setShowUploadPicture(false);
        }}
        width='40%'
        height='40%'
      >
        <FileUploader
          id='fileUploader'
          name='file'
          multiple={false}
          accept={appConst.acceptImageToUpload}
          uploadUrl={`${window.env.apiEndpoint}/api/v1/DistributionList/UploadFile`}
          uploadMode='instantly'
          onUploaded={(e) => {
            const ob = JSON.parse(e.request.responseText);
            const fd = (Form.getInstance(document.getElementById('pictureUploadForm'))).option('formData');

            insert(ob.url, 'extendedImage', null, fd.alternateText, fd.width, fd.height);
            setShowUploadPicture(false);
          }}
          onFilesUploaded={(e) => {
            e.component.reset();
            setShowUploadFiles(false);
          }}
          onInitialized={async (e) => {
            const o = {};
            await SetAuthorizationHeaderAsync(o);
            e.component.option('uploadHeaders', o.headers);
          }}
          onUploadError={(e) => {
            notify({ message: e.request.responseText, shading: true }, 'error');
            e.request.abort();
            e.component.reset();
          }}
        />
        <DxForm
          id='pictureUploadForm'
        >
          <GroupItem colCount={2}>
            <SimpleItem
              dataField='width'
              editorType='dxNumberBox'
              editorOptions={{ value: null }}
            >
            </SimpleItem>
            <SimpleItem
              dataField='height'
              editorType='dxNumberBox'
              editorOptions={{ value: null }}
            >
            </SimpleItem>
            <SimpleItem
              colSpan={2}
              dataField='alternateText'
              editorType='dxTextBox'
            >
            </SimpleItem>
          </GroupItem>
        </DxForm>
      </Popup>}

      {showImageProperties && <Popup
        visible={showImageProperties}
        showTitle={true}
        title='Image Properties...'
        onHiding={() => {
          setShowImageProperties(false);
        }}
        width='40%'
        height='40%'
      >
        <DxForm
          formData={imagePropertiesFormData}
        >
          <GroupItem colCount={2}>
            <FItem
              colSpan={2}
              dataField='index'>
              <Label text="Picture" /> 
              <SelectBox
                itemRender={renderItem}
                fieldRender={fieldRender}
                defaultValue={imagePropertiesFormData.index}
                items={imageList}
                displayExpr= 'src'
                valueExpr= 'id'
                onValueChanged= {(e) => {
                  if (typeof (e.event) === 'undefined') return;
                  const items = e.component.option('items');
                  const ob = items.find(x => x.id === e.value);
                  if (ob) {
                    setImagePropertiesFormData({ ...imagePropertiesFormData, width: ob.width, height: ob.height, har: ob.har, war: ob.war, index: e.value });
                  }
              }}/>
            </FItem>
            <SimpleItem
              dataField='width'
              editorType='dxNumberBox'
              editorOptions={{
                onValueChanged: (e) => {
                  if (typeof (e.event) === 'undefined') return;
                  const newImagePropertiesFormData = { ...imagePropertiesFormData };
                  newImagePropertiesFormData.height = Math.round(newImagePropertiesFormData.har * e.value);
                  setImagePropertiesFormData(newImagePropertiesFormData);
                }
              }}
            >
            </SimpleItem>
            <SimpleItem
              dataField='height'
              editorType='dxNumberBox'
              editorOptions={{
                onValueChanged: (e) => {
                  if (typeof (e.event) === 'undefined') return;
                  const newImagePropertiesFormData = { ...imagePropertiesFormData };
                  newImagePropertiesFormData.width = Math.round(newImagePropertiesFormData.war * e.value);
                  setImagePropertiesFormData(newImagePropertiesFormData);
                }
              }}
            >
            </SimpleItem>
            <SimpleItem
              itemType="button"
              horizontalAlignment="right"
              width="100%"
              buttonOptions={{
                text: 'Set',
                width: '100%',
                onClick: () => {
                  const el = document.getElementById("htmlEditor");
                  const img = el.querySelectorAll('img')[imagePropertiesFormData.index];

                  if (img) {
                    img.width = imagePropertiesFormData.width;
                    img.height = imagePropertiesFormData.height;
                  }
                }
              }}
            >
            </SimpleItem>
            <SimpleItem
              itemType="button"
              horizontalAlignment="left"
              width="100%"
              buttonOptions={{
                text: 'Cancel',
                width: '100%',
                onClick: () => {
                  setShowImageProperties(false);
                }
              }}
            >
            </SimpleItem>
          </GroupItem>
        </DxForm>
      </Popup>}

      {showExplorer &&
        <Explorer visible={showExplorer} setShowExplorer={setShowExplorer} updateHtmlEditorValue={updateHtmlEditorValue} />}
      {showFileExplorer &&
        <FileExplorer visible={showFileExplorer} setShowFileExplorer={setShowFileExplorer} insert={insert} />}
    </>
  );
}

