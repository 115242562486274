import React, { useState } from 'react';
import { Button } from 'devextreme-react/button';
import { LoadPanel } from 'devextreme-react/load-panel';
import notify from 'devextreme/ui/notify';
import './external-mail-system.scss';
import { getAuthorizationTokenAsync } from '../../helpers/data';

export default function (props) {
  const { statusMessage, hasError, addNewMode, entityId, entityType } = props;
  const [showLoadingPanel, setShowLoadingPanel] = useState(false);

  return (
    !addNewMode &&
    <div className="container">
      <div className={hasError ? "errorStatusMessage" : "statusMessage"}>
        {hasError ? "" : "Ok"}
      </div>
        <div>
          <LoadPanel
            shadingColor="rgba(0,0,0,0.4)"
            visible={showLoadingPanel}
          />
          <Button
            visible={hasError}
            text="Retry"
            type="danger"
            hint={statusMessage}
            onClick={async (btn) => {
              try {
                btn.component.option("disabled", true);
                setShowLoadingPanel(true);
                const response = await postData(`${window.env.apiEndpoint}/api/v1/ExternalMailSystem/RetryLastCommand`, `${entityId}#${entityType}`);          
                btn.component.option("disabled", false);
                setShowLoadingPanel(false);
                if (response.ok) {
                  notify({ message: 'Retry command has been issued successfully.', shading: true }, 'success');
                }
              } catch (e) {
                notify({ message: `Server Error Status:${e.message}`, shading: true }, 'error');
              }
            }}
          />
          <Button
            visible={(entityType === 'CommitteeDistributionList' || entityType === 'CSRRoleDistributionList' || entityType === 'DivisionDistributionList')}
            text="Re-Create"
            type="success"
            hint="Re-Create the distribution list."
            onClick={async (btn) => {
              try {
                btn.component.option("disabled", true);
                setShowLoadingPanel(true);
                const response = await postData(`${window.env.apiEndpoint}/api/v1/ExternalMailSystem/ReCreateDistributionList`, entityId);
                btn.component.option("disabled", false);
                setShowLoadingPanel(false);
                if (response.ok) {
                  notify({ message: 'Re-Create distribution list command has been issued successfully.', shading: true }, 'success');
                }
              } catch (e) {
                notify({ message: `Server Error Status:${e.message}`, shading: true }, 'error');
              }
            }}
          />
      </div>
    </div>
  );
}

async function postData(url, bodyString) {
  const token = await getAuthorizationTokenAsync();
  const response = await fetch(url, {
    method: "POST",
    headers: new Headers({
      'Authorization': `Bearer ${token.accessToken}`,
      'Content-Type': 'application/json',
      'Accept': 'application/json',
      'Access-Control-Allow-Credentials': true,
      'Access-Control-Allow-Origin': '*',
      'Access-Control-Allow-Methods': 'GET, POST, OPTIONS, DELETE',
    }),
    body: JSON.stringify(bodyString),
  });

  return response;
}