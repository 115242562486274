import React from 'react';
import './committees.scss';
import {
  DataGrid,
  Column,
  Editing,
  Selection,
  SearchPanel,
  ColumnChooser,
  Grouping,
  GroupPanel,
  FilterPanel,
  FilterRow,
  HeaderFilter,
} from 'devextreme-react/data-grid';
import { Template } from 'devextreme-react/core/template';
import { Popup } from 'devextreme-react/popup';
import EditCommitteeForm from './EditCommitteeForm';
import CurrentSwitch from '../../components/current-switch/current-switch';
import calcGridHeight from '../../helpers/ui';
import {
  CreateAuthorizedStore,
  CreateAuthorizedDataSource,
  getSchoolById,
} from '../../helpers/data';
import AddMemberPopup from './AddMemberPopup';
import { appConst } from '../../AppConst';
import EditSchoolPopup from '../schools/EditSchoolPopup';

const URL = `${window.env.apiEndpoint}/api/v1/CommitteeMemberships`;
const standardRowRenderingMode = { scrolling: { mode: "virtual", rowRenderingMode: "standard" } };
const dataSource = CreateAuthorizedStore(
  {
    loadUrl: `${URL}/GetCommittees`,
    insertUrl: `${URL}/Post`,
    updateUrl: `${URL}/Put`,
    deleteUrl: `${URL}/DeleteCommittee`,
  },
  null, 'Id',
);

const schoolYearsDataSource = CreateAuthorizedStore(
  {
    loadUrl: `${URL}/SchoolYearsLookup`,
  },
  null, 'Value',
);

const committeeGroupsData = CreateAuthorizedDataSource(
  {
    loadUrl: `${window.env.apiEndpoint}/api/v1/CommitteeGroups/CommitteeGroupsLookup`,
  },
  null, 'Value',
  null, 'Header',
);

class Committees extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      showEditForm: false,
      addNewMode: false,
      editFormHeight: 0,
      rowData: {},
      title: '',
      membersLookupData: {},
      showAddMemberPopup: false,
    };

    this.onToolbarPreparing = this.onToolbarPreparing.bind(this);
    this.toolbarItemRender = this.toolbarItemRender.bind(this);
    this.windowResized = this.windowResized.bind(this);
    this.editIconClick = this.editIconClick.bind(this);
    this.hideEditPopup = this.hideEditPopup.bind(this);
    this.committeeGroupsData = committeeGroupsData;
    this.refreshGrid = this.refreshGrid.bind(this);
    this.changeEditFormTitle = this.changeEditFormTitle.bind(this);
    this.setMemberAutoCompleteSearchProperties = this.setMemberAutoCompleteSearchProperties.bind(this);
    this.hideAddMemberPopup = this.hideAddMemberPopup.bind(this);
    this.showHideSchoolEditForm = this.showHideSchoolEditForm.bind(this);
  }

  toolbarItemRender() {
    return (
      <CurrentSwitch grid={this.CommitteesGrid} />
    );
  }

  onToolbarPreparing(e) {
    e.toolbarOptions.items.unshift(
      {
        template: 'switchCurrent',
        location: 'left',
      },
      {
        location: 'after',
        widget: 'dxButton',
        options: {
          icon: 'add',
          onClick: this.addIconClick.bind(this),
        },
      },
    );
  }

  componentDidMount() {
    window.addEventListener('resize', this.windowResized);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.windowResized);
  }

  windowResized() {
    this.CommitteesGrid.instance.option('height', calcGridHeight('CommitteesGrid'));
  }

  editIconClick(rowData) {
    schoolYearsDataSource.load().done((data) => {
      this.setState({
        showEditForm: true,
        committeeId: rowData.Id,
        addNewMode: false,
        schoolYearsData: data,
        rowData: { ...rowData },
        editFormTitle: rowData.Description,
      });
    });
  }

  addIconClick() {
    schoolYearsDataSource.load().done((data) => {
      this.setState({
        showEditForm: true,
        addNewMode: true,
        schoolYearsData: data,
        committeeId: 0,
        editFormTitle: '(New Committee)',
        rowData: { Current: true },
      });
    });
  }

  hideEditPopup() {
    this.setState({
      rowData: {},
      showEditForm: false,
    });
  }

  refreshGrid() {
    this.CommitteesGrid.instance.refresh();
  }

  changeEditFormTitle(committeeName) {
    this.setState(
      { editFormTitle: committeeName },
    );
  }

  // this function is used to set the state from he child component EditCommitteeForm
  setMemberAutoCompleteSearchProperties(data, showAddMemberPopup,
    selectedCommitteeId, selectedSchoolYearId, membersGridRef,
    selectedStartDate, selectedEndDate) {
    this.setState({
      membersLookupData: data,
      showAddMemberPopup,
      selectedCommitteeId,
      selectedSchoolYearId,
      membersGridRef,
      selectedStartDate,
      selectedEndDate,
    });
  }

  hideAddMemberPopup() {
    this.setState({ showAddMemberPopup: false });
  }

  showHideSchoolEditForm(value, schoolId) {
    if (value) {
      getSchoolById(schoolId).then(
        (result) => {
          this.setState({
            schoolRowData: { ...result },
            showEditSchoolForm: true,
          });
        },
      );
    } else {
      this.setState({
        showEditSchoolForm: false,
      });
    }
  }

  renderEditSchoolPopup(isEditFormShown) {
    if (isEditFormShown) {
      const { schoolRowData } = this.state;
      return (
        <EditSchoolPopup
          rowData={schoolRowData}
          title={schoolRowData.Name}
          showHideSchoolEditForm={this.showHideSchoolEditForm}
          refreshMembersGrid={() => this.refs.editCommitteeForm.refreshMembersGrid()}
          addNewMode={false}
          showEditForm={isEditFormShown}
        />
      );
    }
  }

  render() {
    const {
      showEditForm, committeeId, schoolYearsData,
      addNewMode, rowData, editFormHeight, editFormTitle,
      showEditSchoolForm,
      selectedStartDate,
      selectedEndDate,
      membersLookupData,
      showAddMemberPopup,
      selectedCommitteeId,
      selectedSchoolYearId,
      membersGridRef,
    } = this.state;
    return (
      <>
        <div className="title">
          <span>Committees</span>
        </div>
        {this.renderEditSchoolPopup(showEditSchoolForm)}

        <>
          {showEditForm && (
            <Popup
              ref={(ref) => { this.editCommitteePopup = ref; }}
              id="editCommitteePopup"
              visible
              onHiding={(e) => { e.cancel = true; this.refs.editCommitteeForm.cancelData(); }}
              onShown={(e) => {
                this.setState({ editFormHeight: e.component.content().closest('.dx-overlay-content').offsetHeight });
              }}
              {...appConst.defaultPopupOptions}
              title={editFormTitle}
            >
              <EditCommitteeForm
                ref="editCommitteeForm"
                className="editCommitteePopup"
                committeeId={committeeId}
                schoolYearsData={schoolYearsData}
                addNewMode={addNewMode}
                rowData={rowData}
                committeeGroupsData={this.committeeGroupsData}
                refreshGrid={this.refreshGrid}
                editFormHeight={editFormHeight}
                changeEditFormTitle={this.changeEditFormTitle}
                setMemberAutoCompleteSearchProperties={this.setMemberAutoCompleteSearchProperties}
                hideEditPopup={this.hideEditPopup}
                showHideSchoolEditForm={this.showHideSchoolEditForm}
              />
            </Popup>
          )}
          {showAddMemberPopup && (
            <AddMemberPopup
              membersLookupData={membersLookupData}
              showAddMemberPopup={showAddMemberPopup}
              selectedCommitteeId={selectedCommitteeId}
              selectedSchoolYearId={selectedSchoolYearId}
              hideAddMemberPopup={this.hideAddMemberPopup}
              membersGridRef={membersGridRef}
              selectedStartDate={selectedStartDate}
              selectedEndDate={selectedEndDate}
            />
          )}
        </>
        <DataGrid
          id="CommitteesGrid"
          ref={(ref) => { this.CommitteesGrid = ref; }}
          dataSource={dataSource}
          {...appConst.defaultGridOptions}
          {...standardRowRenderingMode}
          onToolbarPreparing={this.onToolbarPreparing}
          height={() => calcGridHeight('CommitteesGrid')}
          onRowDblClick={(e) => {
            this.editIconClick(e.data);
          }}
        >
          <Selection mode="multiple" />
          <SearchPanel
            visible
            width={240}
            placeholder="Search..."
          />
          <Editing
            mode="row"
            allowAdding={false}
            allowDeleting
            allowUpdating
          />
          <Column
            dataField="Description"
            dataType="string"
            sortOrder="asc"
            sortIndex="1"
          />
          <Column dataField="GroupId" dataType="number" calculateDisplayValue="CommitteeGroupName" caption="Group Name" />
          <Column
            dataField="Current"
            filterValue
            dataType="boolean"
            sortOrder="desc"
            sortIndex="0"
          />
          <Column
            type="buttons"
            width={110}
            buttons={['delete', {
              hint: 'Edit',
              icon: 'edit',
              onClick: (e) => {
                this.editIconClick(e.row.data);
              },
            }]}
          />
          <Template name="switchCurrent" render={this.toolbarItemRender} />
          <ColumnChooser
            enabled
            mode="dragAndDrop"
          />
          <Grouping
            allowCollapsing
            autoExpandAll={false}
            contextMenuEnabled
            expandMode="rowClick"
          />
          <GroupPanel
            visible
            allowColumnDragging
          />
          <HeaderFilter
            visible
            allowSearch
          />
          <FilterRow visible />
          <FilterPanel visible />
        </DataGrid>
      </>
    );
  }
}

export default Committees;
