import React, { useState } from 'react';
import notify from 'devextreme/ui/notify';
import { Popup } from 'devextreme-react/popup';
import { Tabs } from 'devextreme-react/tabs';
import Form, {
  GroupItem,
  SimpleItem,
  Label,
  RequiredRule,
  EmptyItem,
} from 'devextreme-react/form';
import DivisionDistributionList from '../../../components/division-distribution-list/DivisionDistributionList';
import SaveAndCancelButtons from '../../../components/save-and-cancel-buttons/SaveAndCancelButtons';
import { CreateAuthorizedStore } from '../../../helpers/data';
import { cancelEditFormData, setFocusOnFirstInput } from '../../../helpers/ui';
import { appConst } from '../../../AppConst';

export default function (props) {

  const tabs = [
    {
      id: 0,
      text: 'Division Information',
      icon: '',
    },
    {
      id: 1,
      text: 'Distribution Lists',
      icon: 'group',
    },
  ];


  let editDivisionForm = null;

  const [selectedTabIndex, setSelectedTabIndex] = useState(0);
  const [divisionId, setDivisionId] = useState(props.rowData.Id);
  const [currentData, setCurrentData] = useState(JSON.stringify(props.rowData));
  const [addNewMode, setAddNewMode] = useState(props.addNewMode);
  const [rowData] = useState(props.rowData);

  function saveData(closePopup) {
    const divisionUrl = `${window.env.apiEndpoint}/api/v1/Divisions`;

    const v = editDivisionForm.instance.validate();
    if (!v.isValid) return false;

    const data = rowData;
    const saveDivisionData = CreateAuthorizedStore(
      { updateUrl: `${divisionUrl}/Put`, insertUrl: `${divisionUrl}/Post` },
      null,
      'Id',
    );

    if (!addNewMode) {
      saveDivisionData.update(divisionId, data).then(
        (result) => {
          setCurrentData(JSON.stringify(rowData));
          props.showHideDivisionEditForm(!closePopup);
        },
        (error) => {
          notify({ message: error.message, shading: true }, 'error');
        },
      );
    } else {
      saveDivisionData.insert(data).then(
        (result) => {
          setDivisionId(result);
          setAddNewMode(false);
          setCurrentData(JSON.stringify(rowData));
          props.showHideDivisionEditForm(!closePopup);
          props.refreshGrid();
        },
        (error) => {
          notify({ message: error.message, shading: true }, 'error');
        },
      );
    }
  }

  function cancelData(e) {
    const editedData = JSON.stringify(rowData);
    cancelEditFormData(currentData, editedData, () => { saveData(true); }, () => { props.showHideDivisionEditForm(false); });
  }

  return (
    <>
      <Popup
        visible={props.showEditForm}
        closeOnOutsideClick={false}
        showCloseButton={true}
        showTitle={true}
        title={props.title}
        onShown={
          (e) => { setFocusOnFirstInput(document.querySelector("#editDivisionForm"));}
        }
        onHiding={
          () => {
            props.showHideDivisionEditForm(false);
          }
        }
      >
      <Tabs
          dataSource={tabs}
          selectedIndex={selectedTabIndex}
          onOptionChanged={(args) => {
            if (args.name === 'selectedIndex' && args.value >= 0) {
              if (args.value === 1 && addNewMode) {
                notify("Division is not saved yet.");
              } else {
                setSelectedTabIndex(args.value);
              }
            }
          }}
        />
      <Form
          id="editDivisionForm"
          formData={rowData}
          {...appConst.defaultFormOptions}
          visible={0 === selectedTabIndex}
          ref={(ref) => { editDivisionForm = ref; }}
        >
          <GroupItem caption=" " colCount={2}>
            <SimpleItem editorType="dxTextBox" dataField="Description">
              <Label text="Description" />
              <RequiredRule />
            </SimpleItem>
            <SimpleItem editorType="dxCheckBox" dataField="Current" editorOptions={{ onContentReady: (e) => { if (addNewMode) e.component.option('value', true); } }}>
              <Label text="Current" />
            </SimpleItem>
          </GroupItem>
          <EmptyItem/>
          <GroupItem colCount={1}>
            <SimpleItem>
              <SaveAndCancelButtons saveData={saveData} cancelData={cancelData} />
            </SimpleItem>
          </GroupItem>
        </Form>
        <div style={{ display: (1 === selectedTabIndex) ? "inline-block":"none" }}>
          <DivisionDistributionList divisionId={divisionId} />
        </div>
    </Popup>
    </>
  );
}
