import React from 'react';
import {
  DataGrid,
  Column,
  Editing,
  Scrolling,
  Selection,
  SearchPanel,
  Grouping,
  GroupPanel,
  Button,
} from 'devextreme-react/data-grid';
import { Template } from 'devextreme-react/core/template';
import CurrentSwitch from '../../components/current-switch/current-switch';
import calcGridHeight from '../../helpers/ui';
import './CSRRoles.scss';
import {
  CreateAuthorizedDataSource,
  loadMembersLookupDataAsync,
  disposeMembersLookupData,
} from '../../helpers/data';
import { appConst } from '../../AppConst';
import EditCSRRoleForm from './EditCSRRoleForm';
import { strings } from '../../locale';

const URL = `${window.env.apiEndpoint}/api/v1/CSRRoles`;

class CSRRoles extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      addNewMode: false,
      showEditForm: false,
      rowData: {},
      title: '',
    };

    this.toolbarItemRender = this.toolbarItemRender.bind(this);
    this.windowResized = this.windowResized.bind(this);
    this.dataSource = CreateAuthorizedDataSource(
      {
        loadUrl: `${URL}/Get`,
        updateUrl: `${URL}/Put`,
        deleteUrl: `${URL}/Delete`,
        insertUrl: `${URL}/Post`,
      }, null, 'Id',
    );

    this.showHideEditForm = this.showHideEditForm.bind(this);
    this.csrRolesGrid = React.createRef();
    this.refreshGrid = this.refreshGrid.bind(this);
    this.onToolbarPreparing = this.onToolbarPreparing.bind(this);
  }

  async componentDidMount() {
    window.addEventListener('resize', this.windowResized);
    this.setState({ membersLookupData: await loadMembersLookupDataAsync() });
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.windowResized);
    if (this.dataSource) {
      this.dataSource.dispose();
    }
    disposeMembersLookupData(this.state.membersLookupData);
  }

  toolbarItemRender() {
    return (
      <CurrentSwitch grid={this.csrRolesGrid.current} />
    );
  }

  windowResized() {
    this.csrRolesGrid.current.instance.option('height', calcGridHeight('csrRolesGrid'));
  }

  editIconClick(rowData) {
    const description = rowData.Description;
    this.setState({
      showEditForm: true,
      rowData: { ...rowData },
      addNewMode: false,
      title: description,
    });
  }

  showHideEditForm(value) {
    this.setState({
      showEditForm: value,
    });
  }

  refreshGrid() {
    this.csrRolesGrid.current.instance.refresh();
  }

  onToolbarPreparing(e) {
    e.toolbarOptions.items.unshift(
      {
        template: 'switchCurrent',
        location: 'left',
      },
      {
        location: 'after',
        widget: 'dxButton',
        options: {
          icon: 'add',
          onClick: () => {
            this.setState({
              showEditForm: true,
              rowData: { Current: true },
              addNewMode: true,
              title: '(New Role)',
            });
          },
        },
      },
    );
  }

  membersDataSource() {
    return CreateAuthorizedDataSource(
      { loadUrl: `${window.env.apiEndpoint}/api/v1/CSRRoles/GetMembersByRoleId` },
      { load: { roleId: this.state.rowData.Id } },
      'CSRRoleMemberId',
    );
  }

  render() {
    const {
      addNewMode, showEditForm, rowData, title, membersLookupData
    } = this.state;
    return (
      <>
        {(showEditForm) && <EditCSRRoleForm
          addNewMode={addNewMode}
          showEditForm={showEditForm}
          rowData={rowData}
          title={title}
          showHideEditForm={this.showHideEditForm}
          refreshGrid={this.refreshGrid}
          membersDataSource={this.membersDataSource()}
          membersLookupData={membersLookupData}
        />}
        <div className="title">
          <span>{`${strings.cor} Roles`}</span>
        </div>
        <DataGrid
          id="csrRolesGrid"
          ref={this.csrRolesGrid}
          dataSource={this.dataSource}
          {...appConst.defaultAdminGridOptions}
          onRowDblClick={(e) => {
            this.editIconClick(e.data);
          }}
          onToolbarPreparing={this.onToolbarPreparing}
          height={() => calcGridHeight('csrRolesGrid')}
        >
          <Selection mode="multiple" />
          <SearchPanel
            visible
            width={240}
            placeholder="Search..."
          />
          <Scrolling
            mode="virtual"
            rowRenderingMode="virtual"
          />
          <Editing
            mode="row"
            allowAdding={false}
            allowDeleting
            allowUpdating
          />
          <Grouping contextMenuEnabled />
          <GroupPanel visible />
          <Column
            dataField="Description"
            dataType="string"
            sortOrder="asc"
            sortIndex="1"
          />
          <Column
            dataField="Current"
            filterValue
            dataType="boolean"
            sortOrder="desc"
            sortIndex="0"
          />
          <Template name="switchCurrent" render={this.toolbarItemRender} />
          <Column type="buttons">
            <Button
              name="edit"
              hint="Edit Role"
              onClick={(e) => {
                this.editIconClick(e.row.data);
              }}
            />
            <Button name="delete" hint="Delete Role" />
          </Column>
        </DataGrid>
      </>
    );
  }
}

export default CSRRoles;
