import React from 'react';
import './EditCommitteeForm.scss';
import { SelectBox } from 'devextreme-react';
import { Button } from 'devextreme-react/button';
import { createStore } from 'devextreme-aspnet-data-nojquery';
import {
  DataGrid,
  Column,
  Editing,
  Selection,
  SearchPanel,
  Paging,
  Export,
  ColumnChooser,
  MasterDetail,
} from 'devextreme-react/data-grid';
import DataSource from 'devextreme/data/data_source';
import MultiView from 'devextreme-react/multi-view';
import Form, { GroupItem, SimpleItem, Label, RequiredRule } from 'devextreme-react/form';
import Tabs from 'devextreme-react/tabs';
import PropTypes from 'prop-types';
import notify from 'devextreme/ui/notify';
import DateBox from 'devextreme-react/date-box';
import { Switch } from 'devextreme-react/switch';
import EditMemberPopup from '../members/EditMemberPopup';
import EditNonMemberPopup from '../non-members/EditNonMemberPopup';
import CommitteeMembershipRoleDetails from './CommitteeMembershipRoleDetails.jsx';

import moment from 'moment';
import {
  SetAuthorizationHeaderAsync,
  CreateAuthorizedStore,
  loadMembersLookupDataAsync,
  CreateAuthorizedDataSource
} from '../../helpers/data';
import calcGridHeight, {
  addMemberBaseViewColumns,
  initMemberBaseViewColumns,
  renderPreferredFirstNameGridCell,
  cancelEditFormData,
  setDateInlineEditing,
  setFocusOnEditor
} from '../../helpers/ui';
import { appConst } from '../../AppConst';

import GridLayout from '../../components/grid-layout-component/GridLayout';
import CommitteeDistributionList from '../../components/committee-distribution-list/CommitteeDistributionList';
import SaveAndCancelButtons from '../../components/save-and-cancel-buttons/SaveAndCancelButtons';

const URL = `${window.env.apiEndpoint}/api/v1/CommitteeMemberships`;
const membersUrl = `${window.env.apiEndpoint}/api/v1/Members`;
const nonMembersUrl = `${window.env.apiEndpoint}/api/v1/NonMembers`;


const tabs = [
  {
    id: 0,
    text: 'Committee',
    icon: 'group',
  },
  {
    id: 1,
    text: 'Members',
    icon: 'group',
  },
  {
    id: 2,
    text: 'Distribution List',
    icon: 'fas fa-mail-bulk'
  },
  {
    id: 3,
    text: 'Roles'
  }
];

class EditCommitteeForm extends React.Component {
  constructor(props) {
    super(props);

    const { addNewMode, committeeId, rowData } = this.props;
    this.state = {
      selectedYearIndex: 0,
      selectedTabIndex: 0,
      addNewMode,
      committeeId,
      currentRowData: JSON.stringify(rowData),
      refDate: new Date(),
      filterByRefDate: true,
    };

    this.renderMultiViewItem = this.renderMultiViewItem.bind(this);
    this.onTabsSelectionChanged = this.onTabsSelectionChanged.bind(this);
    this.onToolbarPreparing = this.onToolbarPreparing.bind(this);
    this.saveData = this.saveData.bind(this);
    this.cancelData = this.cancelData.bind(this);
    this.setGridHeight = this.setGridHeight.bind(this);
    this.setShowMemberEditFormValue = this.setShowMemberEditFormValue.bind(this);
    this.hideMemberEditPopup = this.hideMemberEditPopup.bind(this);
    this.hideMemberEditPopupAndRefreshGrid = this.hideMemberEditPopupAndRefreshGrid.bind(this);
    this.hideNonMemberEditPopup = this.hideNonMemberEditPopup.bind(this);
    this.hideNonMemberEditPopupAndRefreshGrid = this.hideNonMemberEditPopupAndRefreshGrid.bind(this);
    this.roleData = this.roleData.bind(this);
  }

  getCurrentDate() {
    const today = new Date();
    const date = `${today.getFullYear()}-${today.getMonth() + 1}-${today.getDate()}`;
    return date;
  }

  // We cancel the paging for the members gid.
  membersDataSource = new DataSource({
    store: createStore({
      loadUrl: `${URL}/GetAssociatedPeopleByCommittee`,
      updateUrl: `${URL}/UpdateCommitteeMembership`,
      deleteUrl: `${URL}/Delete`,
      key: 'MembershipId',
      onBeforeSend: async (method, ajaxOptions) => {
        await SetAuthorizationHeaderAsync(ajaxOptions);

        // We need extra prameters to be sent to the web api.
        if (method === 'load') {
          const {
            committeeId, selectedYearIndex, refDate, filterByRefDate,
          } = this.state;
          const { schoolYearsData } = this.props;
          ajaxOptions.data.committeeId = committeeId;
          ajaxOptions.data.schoolYearId = schoolYearsData[selectedYearIndex].Value;
          if (filterByRefDate) {
            ajaxOptions.data.refDate = moment(refDate).format('YYYY-MM-DD');
          }
        }
      },
    }),
    paginate: false,
  })

  // this store is used to get members to fill the autocomplete component of
  // name column.
  membersLookupData = new DataSource({
    store: createStore({
      loadUrl: `${URL}/GetAllPeople`,
      key: 'Id',
      onBeforeSend: async (method, ajaxOptions) => {
        await SetAuthorizationHeaderAsync(ajaxOptions);

        if (method === 'load') {
          const { committeeId, selectedYearIndex } = this.state;
          const { schoolYearsData } = this.props;
          ajaxOptions.data.committeeId = committeeId;
          ajaxOptions.data.schoolYearId = schoolYearsData[selectedYearIndex].Value;
        }
      },
    }), paginate: true, pageSize: 20, sort: ['FullName']
  });


  committeeStore = createStore({
    updateUrl: `${URL}/PutCommittee`,
    insertUrl: `${URL}/PostCommittee`,
    key: 'Id',
    onBeforeSend: async (method, ajaxOptions) => {
      await SetAuthorizationHeaderAsync(ajaxOptions);

      if (method === 'update' || method === 'insert') {
        const data = this.committeeForm.instance.option('formData');
        const jd = JSON.stringify(data);
        ajaxOptions.data.values = jd;
        const { committeeId } = this.state;
        ajaxOptions.data.key = committeeId;
      }
    },
    onInserted: (values) => {
      notify('Committee is saved. You can start adding members.');
      this.setState({ committeeId: values, addNewMode: false });
      const { refreshGrid } = this.props;
      refreshGrid();
    },
    onUpdated: () => {
      const { refreshGrid } = this.props;
      refreshGrid();
    },
  });

  roleData() {
    return CreateAuthorizedDataSource(
      {
        loadUrl: `${window.env.apiEndpoint}/api/v1/CommitteeMemberships/GetRoles`,
        insertUrl: `${window.env.apiEndpoint}/api/v1/CommitteeMemberships/AddRole`,
        updateUrl: `${window.env.apiEndpoint}/api/v1/CommitteeMemberships/PutRole`,
        deleteUrl: `${window.env.apiEndpoint}/api/v1/CommitteeMemberships/DeleteRole`,
      },
      {
        load: { committeeId: this.props.committeeId },
        insert: { committeeId: this.props.committeeId },
      },
      'Id', false,
    );
  }

  onToolbarPreparing(e) {
    e.toolbarOptions.items.unshift({
      location: 'after',
      widget: 'dxButton',
      options: {
        icon: 'add',
        onClick: () => {
          const {
            selectedYearIndex,
            committeeId,
            selectedStartDate,
            selectedEndDate,
          } = this.state;
          const { schoolYearsData, setMemberAutoCompleteSearchProperties } = this.props;
          // Set the state of parent component Committees
          setMemberAutoCompleteSearchProperties(
            this.membersLookupData,
            true,
            committeeId,
            schoolYearsData[selectedYearIndex].Value,
            this.membersGrid.instance,
            selectedStartDate,
            selectedEndDate,
          );
        },
      },
    },
      {
        location: 'before',
        widget: 'dxButton',
        options: {
          icon: 'collapse',
          hint: 'Collapse All Rows',
          onClick: () => {
            this.membersGrid.instance.collapseAll(-1);
          }
        }
      }
    );
  }

  refreshMembersGrid() {
    this.membersGrid.instance.refresh();
  }

  onEditorPreparing = (e) => {
    setDateInlineEditing(['CommitteeMembershipStartDate', 'CommitteeMembershipEndDate'], e);
  }

  renderMultiViewItem = () => {
    const { selectedStartDate, selectedEndDate, filterByRefDate, refDate } = this.state;
    return (
      <>
        <GridLayout
          layoutKey="Members"
          gridName="membersGrid"
          pageName="EditCommitteePage"
          gridRef={
            () => this.membersGrid.instance
          }
        />
        <DataGrid
          id="membersGrid"
          ref={(ref) => { this.membersGrid = ref; }}
          dataSource={this.membersDataSource}
          {...appConst.defaultCommitteeMembershipGridOptions}
          onToolbarPreparing={this.onToolbarPreparing}
          // We get the the height of the popup component
          // from the parent page.
          // We turn off paging of the data source, we get all rows from the first hit.
          onCellPrepared={this.onCellPrepared}
          customizeColumns={addMemberBaseViewColumns}
          onEditorPreparing={this.onEditorPreparing}
          onInitialized={(e) => { initMemberBaseViewColumns(e); }}
          keyExpr="Id"
          onCellClick={(e) => {
            if (e.rowType === 'data' && e.column.dataField === "CommitteeRoleName" && !e.component.hasEditData()) {
              var key = e.component.getKeyByRowIndex(e.rowIndex);
              var expanded = e.component.isRowExpanded(key);
              if (expanded) {
                e.component.collapseRow(key);
              } else {
                e.component.expandRow(key);
              }
            }
          }}
        >
          <Selection mode="multiple" />
          <SearchPanel
            visible
            width={240}
            placeholder="Search..."
          />
          <Paging enabled />
          <ColumnChooser
            enabled
            mode="dragAndDrop"
          />
          <Export enabled allowExportSelectedData fileName={`${this.props.schoolYearsData[this.state.selectedYearIndex].Text}${this.committeeForm.instance.option('formData').Description} Members`} />
          <Editing
            mode="row"
            allowAdding={false}
            allowDeleting
            allowUpdating={false}
          />
          <Column
            dataField="PreferredFirstName"
            dataType="string"
            sortOrder="asc"
            sortIndex="1"
            allowEditing={false}
            cellRender={(data) => renderPreferredFirstNameGridCell(this, data)}
          />
          <Column
            dataField="LastName"
            dataType="string"
            sortOrder="asc"
            sortIndex="0"
            allowEditing={false}
          />
          <Column
            dataField="CommitteeRoleName"
            dataType="text"
            caption="Current Roles"
            allowEditing={false}
            alignment="left">
          </Column>
          <Column
            dataField="PhoneNumber"
            dataType="string"
            allowEditing={false}
            visible={false}
          />
          <Column
            dataField="PhoneNumberType"
            dataType="string"
            visible={false}
            allowEditing={false}
          />
          <Column
            dataField="EmailAddress"
            dataType="string"
            allowEditing={false}
          />
          <Column
            dataField="EmailAddressType"
            dataType="string"
            visible={false}
            allowEditing={false}
          />
          <Column
            dataField="PrimarySchoolName"
            dataType="string"
            allowEditing={false}
            cellRender={(data) => (
              <a
                href="#/"
                rel="noopener noreferrer"
                onClick={(e) => {
                  e.preventDefault();
                  this.props.showHideSchoolEditForm(true, data.row.data.PrimarySchoolId);
                }}
              >
                {data.row.data.PrimarySchoolName}
              </a>
            )}
          />
          <Column
            dataField="CommitteeMembershipStartDate"
            dataType="date"
            caption="Start Date"
            format={appConst.dateDisplayFormat}
          />
          <Column
            dataField="CommitteeMembershipEndDate"
            dataType="date"
            caption="End Date"
            format={appConst.dateDisplayFormat}
          />
          <Column
            type="buttons"
            width={110}
            buttons={['delete', 'edit', {
              hint: 'Edit Person',
              icon: 'user',
              onClick: (e) => {
                this.setShowMemberEditFormValue(true, e.row.data.Id, this.props.addNewMode);
              },
            }]}
          />
          <MasterDetail
            enabled={true}
            render={props => {
              return (
                <CommitteeMembershipRoleDetails
                  committeeMembershipId={props.key}
                  committeeId={props.data.CommitteeId}
                  startDate={selectedStartDate}
                  EndDate={selectedEndDate}
                  refreshMasterGrid={() => {
                    this.membersGrid.instance.refresh()
                  }}
                  refDate={filterByRefDate ? moment(refDate).format('YYYY-MM-DD') : null}
                />
              );
            }}
          />
        </DataGrid>
      </>
    )
  }

  async componentDidMount() {
    window.addEventListener('resize', this.setGridHeight);
    this.setState({ membersLookupData: await loadMembersLookupDataAsync() });
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.setGridHeight);
  }

  setGridHeight() {
    this.membersGrid
      && this.membersGrid.instance.option('height', calcGridHeight('membersGrid') - 225);
    this.membersGrid && this.membersGrid.instance.repaint();

    this.rolesGrid
      && this.rolesGrid.instance.option('height', calcGridHeight('rolesGrid') - 10);
    this.rolesGrid && this.rolesGrid.instance.repaint();
  }

  onTabsSelectionChanged(args) {
    if (args.name === 'selectedIndex') {
      const { addNewMode } = this.state;
      if (!addNewMode) {
        this.setState({
          selectedTabIndex: args.value,
        });
        this.setGridHeight();
      } else {
        notify('You are not allowed to add members unless committee is saved.');
      }
      if (args.value === 0) {
        setFocusOnEditor(this.committeeForm.instance, "FirstName");
      }
    }
  }

  cancelData() {
    const { hideEditPopup } = this.props;
    const { currentRowData } = this.state;

    const editedData = JSON.stringify(this.committeeForm.instance.option('formData'));
    cancelEditFormData(currentRowData, editedData, () => { this.saveData(true); }, hideEditPopup);
  }

  afterSave(closePopup) {
    const { hideEditPopup } = this.props;

    this.setState({ currentRowData: JSON.stringify(this.committeeForm.instance.option('formData')) }, () => { if (closePopup) hideEditPopup(); });
  }

  saveData(closePopup) {
    const v = this.committeeForm.instance.validate();
    if (!v.isValid) return false;

    const { addNewMode } = this.state;
    if (addNewMode) {
      this.committeeStore.insert().then(
        (data) => {
          this.afterSave(closePopup);
        },
        (error) => {
          notify({ message: error.message, shading: true }, 'error');
        }
      );
    } else {
      this.committeeStore.update().then(
        (data) => {
          this.afterSave(closePopup);
        },
        (error) => {
          notify({ message: error.message, shading: true }, 'error');
        }
      );
    }
  }

  saveCommitteeButtonOptions = {
    text: 'Save',
    onClick: () => {
      this.saveData();
    },
  }

  setShowMemberEditFormValue(value, key, memberAddNewMode) {
    this.setState({ showLoadingPanel: true });
    const getMemberByIdStore = CreateAuthorizedStore(
      { loadUrl: `${membersUrl}/GetMemberById` }, { load: { Id: key } },
    );
    getMemberByIdStore.load().then(
      (result) => {
        this.setState({
          showMemberEditForm: value,
          rowMemberData: result[0],
        });
      },
      (error) => {
        if (error.message.toLowerCase() === "member could not be found.") {
          const getNonMemberByIdStore = CreateAuthorizedStore(
            { loadUrl: `${nonMembersUrl}/GetNonMemberById` }, { load: { Id: key } },
          );
          getNonMemberByIdStore.load().then(
            (result) => {
              this.setState({
                showNonMemberEditForm: value,
                rowNonMemberData: result[0],
              })
            }
          )
        }
      }
    );
  }

  renderMemberEditPopUp(isEditFormShown) {
    const { rowMemberData, membersLookupData } = this.state;
    if (isEditFormShown) {
      const { showMemberEditForm } = this.state;
      return (
        <EditMemberPopup
          showMemberEditForm={showMemberEditForm}
          rowMemberData={rowMemberData}
          memberAddNewMode={false}
          hideMemberEditPopup={this.hideMemberEditPopup}
          hideMemberEditPopupAndRefreshGrid={this.hideMemberEditPopupAndRefreshGrid}
          membersLookupData={membersLookupData}
        />
      );
    }
  }

  hideMemberEditPopup() {
    this.setState({ showMemberEditForm: false, });
  }

  hideMemberEditPopupAndRefreshGrid() {
    this.hideMemberEditPopup();
    this.membersGrid.instance.refresh();
  }

  renderNonMemberEditPopUp(isEditFormShown) {
    const { rowNonMemberData, membersLookupData } = this.state;
    if (isEditFormShown) {
      const { showNonMemberEditForm } = this.state;
      return (
        <EditNonMemberPopup
          showNonMemberEditForm={showNonMemberEditForm}
          rowMemberData={rowNonMemberData}
          nonMemberAddNewMode={false}
          hideNonMemberEditPopup={this.hideNonMemberEditPopup}
          hideNonMemberEditPopupAndRefreshGrid={this.hideNonMemberEditPopupAndRefreshGrid}
          membersLookupData={membersLookupData}
        />
      );
    }
  }

  hideNonMemberEditPopup() {
    this.setState({ showNonMemberEditForm: false, });
  }

  hideNonMemberEditPopupAndRefreshGrid() {
    this.hideNonMemberEditPopup();
    this.membersGrid.instance.refresh();
  }

  render() {
    const { selectedTabIndex, selectedYearIndex, showMemberEditForm, showNonMemberEditForm, committeeId } = this.state;
    const {
      committeeGroupsData,
      rowData,
      schoolYearsData,
      changeEditFormTitle,
    } = this.props;
    return (
      <>
        {this.renderMemberEditPopUp(showMemberEditForm)}
        {this.renderNonMemberEditPopUp(showNonMemberEditForm)}
        <Tabs
          dataSource={tabs}
          selectedIndex={selectedTabIndex}
          onOptionChanged={this.onTabsSelectionChanged}
        />
        <div style={{ display: (selectedTabIndex === 0) ? 'block' : 'none' }}>
          <Form
            ref={(ref) => { this.committeeForm = ref; }}
            formData={rowData}
            {...appConst.defaultFormOptions}
          >
            <GroupItem caption=" " colCount={3}>
              <SimpleItem
                editorType="dxTextBox"
                dataField="Description"
                editorOptions={{
                  valueChangeEvent: 'keyup',
                  onValueChanged: (e) => {
                    changeEditFormTitle(e.component.option('value'));
                  },
                }}
              >
                <Label text="Name" />
                <RequiredRule message="Name is required" />
              </SimpleItem>
              <SimpleItem
                editorType="dxSelectBox"
                dataField="GroupId"
                editorOptions={{ dataSource: committeeGroupsData, ...appConst.defaultSelectBoxOptions }}
              >
                <Label text="Group" />
              </SimpleItem>
              <SimpleItem
                dataField="Current"
                editorType="dxCheckBox"
                verticalAlignment="bottom"
              >
                <Label text="Current" />
              </SimpleItem>
            </GroupItem>
            <GroupItem colCount={1}>
              <SimpleItem>
                <SaveAndCancelButtons saveData={this.saveData} cancelData={this.cancelData} />
              </SimpleItem>
            </GroupItem>
          </Form>
        </div>
        <div style={{ display: (selectedTabIndex === 1) ? 'block' : 'none' }}>
          <div className="year-button-container">
            <Button
              type="normal"
              stylingMode="contained"
              icon="spinleft"
              onClick={() => {
                if (selectedYearIndex > 0) {
                  this.setState({ selectedYearIndex: selectedYearIndex - 1 });
                  this.schoolYearsDataSelectBox.instance.option('value', schoolYearsData[selectedYearIndex - 1].Value);
                }
              }}
            />
            <SelectBox
              {...appConst.defaultSelectBoxOptions}
              ref={(ref) => { this.schoolYearsDataSelectBox = ref; }}
              dataSource={schoolYearsData}
              deferRendering={false}
              grouped={false}
              showClearButton={false}
              defaultValue={schoolYearsData[0]}
              onSelectionChanged={(e) => {
                // Refresh the members grid when we change the school year.
                // change the state of selectedYearIndex in order to keep track
                // of current year selected and to change the multi view.
                const itm = e.selectedItem;
                const index = schoolYearsData.findIndex((x) => x.Value === itm.Value);
                if (index >= 0) {
                  this.setState({
                    selectedYearIndex: index,
                    selectedStartDate: e.selectedItem.StartDate,
                    selectedEndDate: e.selectedItem.EndDate,
                  });
                  if (this.membersGrid) {
                    this.membersGrid.instance.refresh();
                  }
                }
                this.setGridHeight();
              }}
              onContentReady={(e) => {
                // only  used to set the value of school years select box to the first year.
                e.component.option('value', schoolYearsData[0].Value);
              }}
            />
            <Button
              type="normal"
              stylingMode="contained"
              icon="spinright"
              onClick={() => {
                if (selectedYearIndex < schoolYearsData.length) {
                  this.setState({ selectedYearIndex: selectedYearIndex + 1 });
                  this.schoolYearsDataSelectBox.instance.option('value', schoolYearsData[selectedYearIndex + 1].Value);
                }
              }}
            />
          </div>
          <div className="dx-fieldset" id="ref-date-container">
            <div className="dx-field">
              <div className="dx-field-label">Show active members as of:</div>
              <div className="dx-field-value">
                <DateBox
                  defaultValue={new Date()}
                  type="date"
                  {...appConst.defaultSelectDateBoxOptions}
                  showClearButton={false}
                  onValueChanged={
                    (e) => {
                      this.setState({ refDate: e.value },
                        () => {
                          if (this.state.filterByRefDate) {
                            this.membersGrid.instance.refresh();
                          }
                        });
                    }
                  }
                />
              </div>
              <div className="dx-field-value">
                <Switch
                  defaultValue
                  onValueChanged={
                    (e) => {
                      this.setState({ filterByRefDate: e.value },
                        () => {
                          this.membersGrid.instance.refresh();
                        });
                    }
                  }
                />
              </div>
            </div>
          </div>
          <MultiView
            ref={(ref) => { this.membersMultiView = ref; }}
            dataSource={schoolYearsData}
            selectedIndex={selectedYearIndex}
            itemRender={this.renderMultiViewItem}
            onOptionChanged={(e) => {
              // This is used to set the school years select box value when
              // we swipe left or right the multi view.
              if (e.name === 'selectedIndex') {
                if (selectedYearIndex < schoolYearsData.length - 1) {
                  this.setState({ selectedYearIndex: e.value });
                  this.schoolYearsDataSelectBox.instance.option('value', schoolYearsData[e.value].Value);
                }
              }
            }}
          />
        </div>
        <div style={{ display: (selectedTabIndex === 2) ? 'block' : 'none' }}>
          <CommitteeDistributionList
            committeeId={committeeId}
            schoolYearsData={this.props.schoolYearsData} />
        </div>
        <div style={{ display: (selectedTabIndex === 3) ? 'block' : 'none' }}>
          <DataGrid
            id="rolesGrid"
            ref={(ref) => { this.rolesGrid = ref; }}
            dataSource={this.roleData()}
            keyExpr="Id"
            {...appConst.defaultGridOptions}
            onInitNewRow={
              (e) => {
                e.data.Current = true;
              }
            }
          >
            <Editing
              mode="row"
              allowAdding
              allowDeleting
              allowUpdating
            />
            <Column
              dataField="Name"
              dataType="text"
              caption="Name">
              <RequiredRule />
            </Column>
            <Column
              dataField="Current"
              dataType="boolean"
              caption="Current" />
            <Column
              dataField="OnlyAllowOne"
              dataType="boolean"
              caption="Only Allow One" />
          </DataGrid>
        </div>
      </>
    );
  }
}

EditCommitteeForm.propTypes = {
  schoolYearsData: PropTypes.array.isRequired,
  editFormHeight: PropTypes.number.isRequired,
  rowData: PropTypes.object.isRequired,
  refreshGrid: PropTypes.func.isRequired,
  addNewMode: PropTypes.bool.isRequired,
  committeeId: PropTypes.number.isRequired,
  committeeGroupsData: PropTypes.object.isRequired,
};
export default EditCommitteeForm;
