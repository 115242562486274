import React from 'react';
import Tabs from 'devextreme-react/tabs';
import notify from 'devextreme/ui/notify';
import Form,
{
  GroupItem,
  SimpleItem,
  RequiredRule,
  EmptyItem,
} from 'devextreme-react/form';

import { Popup } from 'devextreme-react/popup';
import {
  DataGrid,
  Column,
  Editing,
  Scrolling,
  Selection,
  SearchPanel,
  ColumnChooser,
  Grouping,
  GroupPanel,
  FilterPanel,
  FilterRow,
  HeaderFilter,
  SortByGroupSummaryInfo,
  Summary,
  TotalItem,
  Button as GridButton,
} from 'devextreme-react/data-grid';
import EditSchoolPopup from '../schools/EditSchoolPopup';
import {
  CreateAuthorizedStore,
  getMemberById,
  getSchoolById,
} from '../../helpers/data';
import {
  addMemberBaseViewColumns,
  initMemberBaseViewColumns,
  renderPreferredFirstNameGridCell,
  cancelEditFormData,
  setFocusOnFirstInput,
} from '../../helpers/ui';
import { appConst } from '../../AppConst';
import { strings } from '../../locale';

import EditMemberPopup from '../members/EditMemberPopup';
import GridLayout from '../../components/grid-layout-component/GridLayout';
import CSRRoleDistributionList from '../../components/csrrole-distribution-list/CSRRoleDistributionList';
import SaveAndCancelButtons from '../../components/save-and-cancel-buttons/SaveAndCancelButtons';

const csrRolesUrl = `${window.env.apiEndpoint}/api/v1/CSRRoles`;

const tabs = [
  {
    id: 0,
    text: `${strings.cor} Role`,
    icon: 'user',
  },
  {
    id: 1,
    text: 'Members',
    icon: 'group',
  },
  {
    id: 3,
    text: 'Distribution Lists',
    icon: 'fas fa-mail-bulk',
  },
];

class EditCSRRoleForm extends React.Component {
  constructor(props) {
    super(props);
    const { rowData, addNewMode } = this.props;
    this.state = {
      selectedTabIndex: 0,
      showEditSchoolForm: false,
      currentRowData: JSON.stringify(rowData),
      rowData: rowData,
      addNewMode: addNewMode,
      roleId: rowData.Id,
    };
    this.onTabsSelectionChanged = this.onTabsSelectionChanged.bind(this);

    this.renderGrid = this.renderGrid.bind(this);
    this.cancelData = this.cancelData.bind(this);
    this.saveData = this.saveData.bind(this);

    this.showHideSchoolEditForm = this.showHideSchoolEditForm.bind(this);
    this.refreshMembersGrid = this.refreshMembersGrid.bind(this);

    this.hideMemberEditPopup = this.hideMemberEditPopup.bind(this);
    this.hideMemberEditPopupAndRefreshGrid = this.hideMemberEditPopupAndRefreshGrid.bind(this);
    this.afterSave = this.afterSave.bind(this);
  }

  onTabsSelectionChanged(args) {
    if (args.name === 'selectedIndex') {
      const { addNewMode } = this.state;
      if (addNewMode && args.value > 0) {
        notify(`${strings.cor} Role is not saved yet.`);
      } else {
        this.setState({
          selectedTabIndex: args.value,
        });
      }
    }
  }

  showHideSchoolEditForm(value) {
    this.setState({
      showEditSchoolForm: value,
    });
  }

  renderEditSchoolPopup(isEditFormShown) {
    if (isEditFormShown) {
      const { schoolRowData } = this.state;
      return (
        <EditSchoolPopup
          rowData={schoolRowData}
          title={schoolRowData.Name}
          showHideSchoolEditForm={this.showHideSchoolEditForm}
          refreshMembersGrid={this.refreshMembersGrid}
          addNewMode={false}
          showEditForm={isEditFormShown}
        />
      );
    }
  }

  afterSave(closePopup) {
    const { showHideEditForm, refreshGrid } = this.props;

    this.setState({ currentRowData: JSON.stringify(this.state.rowData) }, () => { showHideEditForm(!closePopup); refreshGrid(); });
  }

  saveData(closePopup) {
    const v = this.editCSRForm.instance.validate();
    if (!v.isValid) return false;

    const { rowData, addNewMode, roleId } = this.state;
    const data = rowData;
    const saveCSRRoleData = CreateAuthorizedStore(
      { updateUrl: `${csrRolesUrl}/Put`, insertUrl: `${csrRolesUrl}/Post` },
      null,
      'Id',
    );

    if (!addNewMode) {
      saveCSRRoleData.update(roleId, data).then(
        (result) => {
          this.afterSave(closePopup);
        },
        (error) => {
          notify({ message: error.message, shading: true }, 'error');
        },
      );
    } else {
      saveCSRRoleData.insert(data).then(
        (result) => {
          this.setState({ roleId: result, addNewMode: false });
          this.afterSave(closePopup);
        },
        (error) => {
          notify({ message: error.message, shading: true }, 'error');
        },
      );
    }
  }

  cancelData(e) {
    const { currentRowData, rowData } = this.state;
    const { showHideEditForm } = this.props;
   
    const editedData = JSON.stringify(rowData);
    cancelEditFormData(currentRowData, editedData, () => { this.saveData(true); }, () => { showHideEditForm(false); });
  }

  refreshMembersGrid() {
    this.membersGrid.instance.refresh();
  }

  editIconClick(key) {
    getMemberById(key).then(
      (result) => {
        this.setState({
          showMemberEditForm: true,
          rowMemberData: result,
          memberAddNewMode: false,
        });
      },
    );
  }

  renderGrid() {
    const { selectedTabIndex } = this.state;
    const { membersDataSource } = this.props;
    if (selectedTabIndex === 1) {
      return (
        <>
          <GridLayout
            layoutKey="Members"
            gridName="membersGrid"
            pageName="EditCSRRolePage"
            gridRef={
              () => this.membersGrid.instance
            }
          />
          <DataGrid
            id="membersGrid"
            ref={(ref) => { this.membersGrid = ref; }}
            dataSource={membersDataSource}
            onToolbarPreparing={this.onToolbarPreparing}
            {...appConst.defaultGridOptions}
            height={window.innerHeight * 0.75}
            customizeColumns={addMemberBaseViewColumns}
            onRowDblClick={(e) => {
              this.editIconClick(e.data.Id);
            }}
            onInitialized={(e) => { initMemberBaseViewColumns(e); }}
          >
            <Scrolling
              mode="virtual"
              rowRenderingMode="virtual"
            />
            <Selection
              mode="multiple"
            />
            <Selection
              mode="multiple"
            />
            <SearchPanel
              visible
              width={240}
              placeholder="Search..."
            />
            <Editing
              mode="popup"
              allowAdding={false}
              allowDeleting={false}
              allowUpdating
            />
            <ColumnChooser
              enabled
              mode="dragAndDrop"
            />
            <Grouping
              allowCollapsing
              autoExpandAll={false}
              contextMenuEnabled
              expandMode="rowClick"
            />
            <GroupPanel
              visible
              allowColumnDragging
            />
            <HeaderFilter
              visible
              allowSearch
            />
            <SortByGroupSummaryInfo
              summaryItem="count"
              sortOrder="desc"
            />
            <FilterRow
              visible
            />
            <FilterPanel
              visible
            />
            <Column
              dataField="PreferredFirstName"
              dataType="string"
              sortIndex="1"
              sortOrder="asc"
              cellRender={(data) => renderPreferredFirstNameGridCell(this, data)}
            />
            <Column
              dataField="LastName"
              dataType="string"
              sortIndex="0"
              sortOrder="asc"
            />
            <Column
              dataField="PhoneNumber"
              dataType="string"
            />
            <Column
              dataField="PhoneNumberType"
              dataType="string"
              visible={false}
            />
            <Column
              dataField="EmailAddress"
              dataType="string"
            />
            <Column
              dataField="EmailAddressType"
              dataType="string"
              visible={false}
            />
            <Column
              dataField="PrimarySchoolName"
              dataType="string"
              cellRender={(data) => (
                <a
                  href="#/"
                  rel="noopener noreferrer"
                  onClick={(e) => {
                    e.preventDefault();
                    getSchoolById(data.row.data.PrimarySchoolId).then(
                      (result) => {
                        this.setState({
                          schoolRowData: { ...result },
                          showEditSchoolForm: true,
                        });
                      },
                    );
                  }}
                >
                  {data.row.data.PrimarySchoolName}
                </a>
              )}
            />
            <Column type="buttons">
              <GridButton
                name="edit"
                hint="Edit Member"
                onClick={
                  (e) => this.editIconClick(e.row.data.Id)
                }
              />
            </Column>
            <Summary>
              <TotalItem
                column="PreferredFirstName"
                summaryType="count"
              />
            </Summary>
          </DataGrid>
        </>
      );
    }

    return (<></>);
  }

  renderMemberEditPopUp(isEditFormShown) {
    const { rowMemberData } = this.state;
    if (isEditFormShown) {
      const { showMemberEditForm } = this.state;
      return (
        <EditMemberPopup
          showMemberEditForm={showMemberEditForm}
          rowMemberData={rowMemberData}
          memberAddNewMode={false}
          hideMemberEditPopup={this.hideMemberEditPopup}
          hideMemberEditPopupAndRefreshGrid={this.hideMemberEditPopupAndRefreshGrid}
          membersLookupData={this.props.membersLookupData}
        />
      );
    }
  }

  hideMemberEditPopup() {
    this.setState({
      showMemberEditForm: false,
    });
  }

  hideMemberEditPopupAndRefreshGrid() {
    this.hideMemberEditPopup();
    this.refreshMembersGrid();
  }

  render() {
    const { showEditForm, title } = this.props;
    const { selectedTabIndex, showEditSchoolForm, showMemberEditForm, rowData, roleId } = this.state;
    return (
      <>
        {this.renderEditSchoolPopup(showEditSchoolForm)}
        {this.renderMemberEditPopUp(showMemberEditForm)}
        <Popup
          title={title && title}
          visible={showEditForm}
          {...appConst.defaultPopupOptions}
          onHiding={
            (e) => {
              e.cancel = true;
              this.cancelData(e);
            }
          }
          onShown={
            (e) => {
              this.setState({
                currentRowData: JSON.stringify(rowData),
                title,
              });
              setFocusOnFirstInput(document.querySelector("#editCSRForm"));
            }
          }
        >
          <Tabs
            dataSource={tabs}
            selectedIndex={selectedTabIndex}
            onOptionChanged={this.onTabsSelectionChanged}
          />
          <div className={(selectedTabIndex === 0) ? 'tab-form-container-visible' : 'tab-container-invisible'}>
            <Form
              formData={rowData}
              id="editCSRForm"
              ref={(ref) => { this.editCSRForm = ref; }}
            >
              <GroupItem caption="" colCount={2}>
                <EmptyItem />
                <EmptyItem />
                <SimpleItem
                  dataField="Description"
                >
                  <RequiredRule />
                </SimpleItem>
                <SimpleItem
                  dataField="Current"
                  editorType="dxCheckBox"
                />
                <EmptyItem />
              </GroupItem>
              <GroupItem colCount={1}>
                <SimpleItem>
                  <SaveAndCancelButtons saveData={this.saveData} cancelData={this.cancelData} />
                </SimpleItem>
              </GroupItem>
            </Form>
          </div>
          <div className={(selectedTabIndex === 1) ? 'tab-form-container-visible' : 'tab-container-invisible'}>
            {this.renderGrid()}
          </div>
          <div className={(selectedTabIndex === 2) ? 'tab-form-container-visible' : 'tab-container-invisible'}>
            <CSRRoleDistributionList
              roleId={roleId}/>
          </div>
        </Popup>
      </>
    );
  }
}

export default EditCSRRoleForm;
