import React from 'react';
import '../../pages.scss';
import {
  DataGrid,
  Column,
  Editing,
  Scrolling,
  Selection,
  SearchPanel,
  ColumnChooser,
  Grouping,
  GroupPanel,
  FilterPanel,
  FilterRow,
  HeaderFilter,
} from 'devextreme-react/data-grid';
import { Template } from 'devextreme-react/core/template';
import { LoadPanel } from 'devextreme-react/load-panel';
import CurrentSwitch from '../../../components/current-switch/current-switch';
import calcGridHeight from '../../../helpers/ui';
import {
  CreateAuthorizedDataSource,
} from '../../../helpers/data';
import { appConst } from '../../../AppConst';
import EditDivisionForm from './EditDivisionForm';

const divisionsUrl = `${window.env.apiEndpoint}/api/v1/Divisions`;

class Divisions extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      showEditForm: false,
      showLoadingPanel: false,
      title: '',
      rowData: {},
      addNewMode: false,
    };
    this.showHideDivisionEditForm = this.showHideDivisionEditForm.bind(this);
    this.windowResized = this.windowResized.bind(this);
    this.editIconClick = this.editIconClick.bind(this);
    this.onToolbarPreparing = this.onToolbarPreparing.bind(this);
    this.toolbarItemRender = this.toolbarItemRender.bind(this);
    this.dataSource = CreateAuthorizedDataSource(
      {
        loadUrl: `${divisionsUrl}/Get`,
        insertUrl: `${divisionsUrl}/Post`,
        updateUrl: `${divisionsUrl}/Put`,
        deleteUrl: `${divisionsUrl}/Delete`,
      },
      null, 'Id',
    );
    this.divisionsGrid = React.createRef();
  }

  toolbarItemRender() {
    return (
      <CurrentSwitch grid={this.divisionsGrid.current} />
    );
  }

  async componentDidMount() {
    window.addEventListener('resize', this.windowResized);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.windowResized);
  }

  windowResized() {
    this.designationsGrid.instance.option('height', calcGridHeight('divisionsGrid'));
  }

  editIconClick(key, name) {
    this.setState({ showEditForm: true, title:name });
  }

  showHideDivisionEditForm(value) {
    this.setState({ showEditForm: value }, () => this.divisionsGrid.current.instance.refresh());
  }

  onToolbarPreparing(e) {
    e.toolbarOptions.items.unshift(
      {
        template: 'switchCurrent',
        location: 'left',
      },
      {
        location: 'after',
        widget: 'dxButton',
        options: {
          icon: 'add',
          onClick: () => {
            this.setState({
              showEditForm: true,
              rowData: { Current: true },
              addNewMode: true,
              title: '(New Division)',
            });
          },
        },
      },
    );
  }

  render() {
    const {
      showEditForm,
      addNewMode, rowData, title,
    } = this.state;
    return (
      <>
        <div className="title">
          <span>Divisions</span>
        </div>
        <LoadPanel
          visible={this.state.showLoadingPanel}
          showIndicator
          shading
          showPane
          closeOnOutsideClick={false}
        />
        {(showEditForm) && <EditDivisionForm showEditForm={showEditForm} showHideDivisionEditForm={this.showHideDivisionEditForm} rowData={rowData} addNewMode={addNewMode} title={title} refreshGrid={() => this.divisionsGrid.current.instance.refresh() } />}
        <DataGrid
          id="divisionsGrid"
          ref={this.divisionsGrid}
          dataSource={this.dataSource}
          {...appConst.defaultGridOptions}
          onToolbarPreparing={this.onToolbarPreparing}
          height={() => calcGridHeight('divisionsGrid')}
          onRowDblClick={(e) => {
            this.setState({ rowData: e.data, addNewMode: false })
            this.editIconClick(e.key, e.data.Description);
          }}
        >
          <Selection mode="multiple" />
          <SearchPanel
            visible
            width={240}
            placeholder="Search..."
          />
          <Scrolling
            mode="virtual"
            rowRenderingMode="virtual"
          />
          <Editing
            mode="row"
            allowAdding={false}
            allowDeleting
            allowUpdating
          />
          <Column
            dataField="Description"
            dataType="string"
            sortOrder="asc"
            sortIndex="1"
          />
          <Column
            dataField="Current"
            filterValue
            dataType="boolean"
            sortOrder="desc"
            sortIndex="0"
          />
          <Template name="switchCurrent" render={this.toolbarItemRender} />
          <Column
            type="buttons"
            width={110}
            buttons={['delete', {
              hint: 'Edit',
              icon: 'edit',
              onClick: (e) => {
                this.setState({ rowData: e.row.data, addNewMode:false })
                this.editIconClick(e.row.key, e.row.data.Description);
              },
            }]}
          />
          <ColumnChooser
            enabled
            mode="dragAndDrop"
          />
          <Grouping
            allowCollapsing
            autoExpandAll={false}
            contextMenuEnabled
            expandMode="rowClick"
          />
          <GroupPanel
            visible
            allowColumnDragging
          />
          <HeaderFilter
            visible
            allowSearch
          />
          <FilterRow visible />
          <FilterPanel visible />
        </DataGrid>
      </>
    );
  }
}

export default Divisions;


