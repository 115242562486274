import moment from 'moment';
import { setFocusOnFirstInput, convertToTZ } from './helpers/ui';

const defaultGridOptions = {
  remoteOperations: true,
  rowAlternationEnabled: true,
  showBorders: true,
  allowColumnReordering: true,
  allowColumnResizing: true,
  columnResizingMode: "nextColumn",
  hoverStateEnabled: true,
  wordWrapEnabled: true,
  onRowDblClick: (e) => {
    e.component.editRow(e.rowIndex)
  },
  pager: {
    showPageSizeSelector: true,
    allowedPageSizes: [10, 20, 50, 100],
    showInfo: true,
    visible: true,
    showInGroupFooter: true
  },
  summary: {
    groupItems: [{
      column: "Id",
      summaryType: "count"
    }]
  },
  grouping: {
    allowCollapsing: true,
    autoExpandAll: false,
    contextMenuEnabled: true,
    expandMode: "rowClick"
  },
  groupPanel: {
    visible: true,
    allowColumnDragging: true,
  },
  filterPanel: {
    visible: true,
  },
  export: {
    enabled: true,
    allowExportSelectedData: true,
  },
  onExporting: (e) => {
    e.fileName = e.element.id;
  },
  scrolling: {
    mode: "virtual",
    rowRenderingMode: "virtual",
  },
};

const defaultCommitteeCopyGridOptions = {
  remoteOperations: false,
  rowAlternationEnabled: true,
  showBorders: true,
  allowColumnReordering: true,
  allowColumnResizing: true,
  columnResizingMode: "nextColumn",
  hoverStateEnabled: true,
  wordWrapEnabled: true,
  pager: {
    showPageSizeSelector: true,
    allowedPageSizes: [10, 20, 50, 100],
    showInfo: true,
    visible: false,
    showInGroupFooter: false
  },
  paging: {
    enabled:false
  },
  filterPanel: {
    visible: false,
  },
  scrolling: {
    mode: "Standard",
    rowRenderingMode: "standard",
  },
};

function selectDateBoxSetDate(e) {
  const txt = e.component.option('text').trim();
  if (txt === "") return;
  for (const format of appConst.acceptedDateInputs) {
    let isValid = moment(txt, format, true).isValid();
    if (isValid) {
      var date = moment(txt, format).format('YYYY-MM-DD');
      e.component.reset();
      e.component.option('value', date);
      break; //this will stop further evaluation
    }
  }
}

function selectDateTimeBoxSetDateTime(e) {
  const txt = e.component.option('text').trim();
  if (txt === "") return;
  let isValid = false;
  for (const format of appConst.acceptedDateInputs) {
    var tdFormats = [];
    tdFormats[0] = format + ' HH:mm';
    tdFormats[1] = format + ' hh:mm a';
    tdFormats[2] = format + ' h:mm a';
    tdFormats[3] = format + ' hh:mma';
    tdFormats[4] = format + ' h:mma';
    for (const tdFormat of tdFormats) {
      isValid = moment(txt, tdFormat, true).isValid();
      if (isValid) {
        var dateTime = moment(txt, tdFormat).format('YYYY-MM-DD hh:mm a');
        e.component.reset();
        e.component.option('value', dateTime);
        break;
      }
    }
    if (isValid) break;
  }
}

export const appConst = {
  appName: 'Epitome',
  release: '2023.2',
  dateDisplayFormat: 'yyyy-MM-dd',
  dateAndTimeDisplayFormat: 'yyyy-MM-dd hh:mm a',
  acceptedDateInputs: [
    //One-digit month and day, no year
    'M-D',
    'M/D',
    'M.D',
    'M D',
    //Two-digit month and day, no year
    'MM-DD',
    'MM/DD',
    'MM.DD',
    'MM DD',
    //One-digit month and day, two-digit year
    'M-D-YY',
    'M/D/YY',
    'M.D.YY',
    'M D YY',
    //Two-digit month and day, two-digit year
    'MM-DD-YY',
    'MM/DD/YY',
    'MM.DD.YY',
    'MM DD YY',
    //One-digit month and day, four-digit year
    'M-D-YYYY',
    'M/D/YYYY',
    'M.D.YYYY',
    'M D YYYY',
    //Two-digit month and day, four-digit year
    'MM-DD-YYYY',
    'MM/DD/YYYY',
    'MM.DD.YYYY',
    'MM DD YYYY',
    //Two-digit year, one-digit month and day
    'YY-M-D',
    'YY/M/D',
    'YY.M.D',
    'YY M D',
    //Two-digit year, two-digit month and day
    'YY-MM-DD',
    'YY/MM/DD',
    'YY.MM.DD',
    'YY MM DD',
    'YYMMDD',
    //Four-digit year, one-digit month and day
    'YY-M-D',
    'YY/M/D',
    'YY.M.D',
    'YY M D',
    //Four-digit year, two-digit month and day
    'YYYY-MM-DD',
    'YYYY/MM/DD',
    'YYYY.MM.DD',
    'YYYY MM DD',
    'YYYYMMDD',
    //One-digit day, short month name, no year
    'D-MMM',
    'D/MMM',
    'D.MMM',
    'D MMM',
    //Two-digit day, short month name, no year
    'DD-MMM',
    'DD/MMM',
    'DD.MMM',
    'DD MMM',
    //One-digit day, short month name, two-digit year
    'D-MMM-YY',
    'D/MMM/YY',
    'D.MMM.YY',
    'D MMM YY',
    //Two-digit day, short month name, two-digit year
    'DD-MMM-YY',
    'DD/MMM/YY',
    'DD.MMM.YY',
    'DD MMM YY',
    //One-digit day, short month name, four-digit year
    'D-MMM-YYYY',
    'D/MMM/YYYY',
    'D.MMM.YYYY',
    'D MMM YYYY',
    //Two-digit day, short month name, four-digit year
    'DD-MMM-YYYY',
    'DD/MMM/YYYY',
    'DD.MMM.YYYY',
    'DD MMM YYYY',
    //Short month name, one-digit day, no year
    'MMM-D',
    'MMM/D',
    'MMM.D',
    'MMM D',
    //Short month name, two-digit day, no year
    'MMM-DD',
    'MMM/DD',
    'MMM.DD',
    'MMM DD',
    //Short month name, one-digit day, two-digit year
    'MMM-D-YY',
    'MMM/D/YY',
    'MMM.D.YY',
    'MMM D YY',
    'MMM D, YY',
    //Short month name, two-digit day, two-digit year
    'MMM-DD-YY',
    'MMM/DD/YY',
    'MMM.DD.YY',
    'MMM DD YY',
    'MMM DD, YY',
    //Short month name, one-digit day, four-digit year
    'MMM-D-YYYY',
    'MMM/D/YYYY',
    'MMM.D.YYYY',
    'MMM D YYYY',
    'MMM D, YYYY',
    //Short month name, two-digit day, four-digit year
    'MMM-DD-YYYY',
    'MMM/DD/YYYY',
    'MMM.DD.YYYY',
    'MMM DD YYYY',
    'MMM DD, YYYY',
    //One-digit day, long month name, no year
    'D-MMMM',
    'D/MMMM',
    'D.MMMM',
    'D MMMM',
    //Two-digit day, long month name, no year
    'DD-MMMM',
    'DD/MMMM',
    'DD.MMMM',
    'DD MMMM',
    //One-digit day, long month name, two-digit year
    'D-MMMM-YY',
    'D/MMMM/YY',
    'D.MMMM.YY',
    'D MMMM YY',
    //Two-digit day, long month name, two-digit year
    'DD-MMMM-YY',
    'DD/MMMM/YY',
    'DD.MMMM.YY',
    'DD MMMM YY',
    //One-digit day, long month name, four-digit year
    'D-MMMM-YYYY',
    'D/MMMM/YYYY',
    'D.MMMM.YYYY',
    'D MMMM YYYY',
    //Two-digit day, long month name, four-digit year
    'DD-MMMM-YYYY',
    'DD/MMMM/YYYY',
    'DD.MMMM.YYYY',
    'DD MMMM YYYY',
    //long month name, one-digit day, no year
    'MMMM-D',
    'MMMM/D',
    'MMMM.D',
    'MMMM D',
    //long month name, two-digit day, no year
    'MMMM-DD',
    'MMMM/DD',
    'MMMM.DD',
    'MMMM DD',
    //long month name, one-digit day, two-digit year
    'MMMM-D-YY',
    'MMMM/D/YY',
    'MMMM.D.YY',
    'MMMM D YY',
    'MMMM D, YY',
    //long month name, two-digit day, two-digit year
    'MMMM-DD-YY',
    'MMMM/DD/YY',
    'MMMM.DD.YY',
    'MMMM DD YY',
    'MMMM DD, YY',
    //long month name, one-digit day, four-digit year
    'MMMM-D-YYYY',
    'MMMM/D/YYYY',
    'MMMM.D.YYYY',
    'MMMM D YYYY',
    'MMMM D, YYYY',
    //long month name, two-digit day, four-digit year
    'MMMM-DD-YYYY',
    'MMMM/DD/YYYY',
    'MMMM.DD.YYYY',
    'MMMM DD YYYY',
    'MMMM DD, YYYY',
  ],
  postalCodePattern: "^[A-Z][0-9][A-Z] ?[0-9][A-Z][0-9]$",
  postalCodeValidationMessage: "Invalid postal code.",
  emailPattern: "^[\\w-\\.]+@([\\w-]+\\.)+[\\w-]{2,4}$",
  emailValidationMessage: "Invalid email format.",
  emailAddressPatternPattern: "^[a-z0-9_.-]+$",
  emailAddressPatternValidationMessage: "Invalid email address pattern. Only characters allowed in an email address are valid in the email address pattern.",
  confirmDialogContent: 'Are you sure?',
  confirmDeleteDialogTitle: 'Delete Confirmation',
  confirmCancelDialogTitle: 'Cancel Confirmation',
  confirmCopyReplaceDestinationContent: 'Copying will delete all committee membership records in the destination year.  Do you want to continue?',
  phonePattern: "^\\(([0-9]{3})\\)\\s([0-9]{3})-([0-9]{4})$",
  phone10DigitsPattern: "^\\d{10}$",
  phoneEditorOptions: {
    mask: '(X00) 000-0000',
    maskRules: {
      X: /[02-9]/
    },
    useMaskedValue: false,
    maskInvalidMessage: 'Invalid phone format.'
  },
  defaultAddressInput: {
    inputAttr: { 'style': "text-transform: uppercase" }
  },
  currencyFormat: "$#0.00",
  defaultSelectBoxOptions: {
    valueExpr: 'Value',
    displayExpr: 'Text',
    searchEnabled: true,
    searchMode: 'startswith',
    minSearchLength: 0,
    showDataBeforeSearch: true,
    searchTimeout: 200,
    showClearButton: true,
    grouped: true
  },
  memberBaseViewColumns: [
    { caption: 'Id', dataField: 'Id', visible: false },
    { caption: 'Preferred First Name', dataField: 'PreferredFirstName', visible: false },
    { caption: 'Full Name', dataField: 'FullName', visible: false },
    { caption: 'First Name', dataField: 'FirstName', visible: false },
    { caption: 'Last Name', dataField: 'LastName', visible: false },
    { caption: 'Preferred First Name Original', dataField: 'PreferredFirstNameOriginal', visible: false },
    { caption: 'Previous Last Name', dataField: 'PreviousLastName', visible: false },
    { caption: 'Previous First Name', dataField: 'PreviousFirstName', visible: false },
    { caption: 'Salutation', dataField: 'Salutation', visible: false },
    { caption: 'Pronouns', dataField: 'Pronouns', visible: false },
    { caption: 'Address1', dataField: 'Address1', visible: false },
    { caption: 'Address2', dataField: 'Address2', visible: false },
    { caption: 'City', dataField: 'City', visible: false },
    { caption: 'Province', dataField: 'Province', visible: false },
    { caption: 'PostalCode', dataField: 'PostalCode', visible: false },
    { caption: 'BirthDate', dataField: 'BirthDate', visible: false, format:'yyyy-MM-dd', dataType: 'datetime' },
    { caption: 'Gender', dataField: 'Gender', visible: false },
    { caption: 'Certificate Number', dataField: 'CertificateNumber', visible: false },
    { caption: 'Start Date', dataField: 'StartDate', visible: false, format:'yyyy-MM-dd', dataType: 'datetime' },
    { caption: 'Contract Type', dataField: 'ContractType', visible: false },
    { caption: 'Membership Type', dataField: 'MembershipType', visible: false },
    { caption: 'Employment Status', dataField: 'EmploymentStatus', visible: false },
    { caption: 'Date Inducted', dataField: 'DateInducted', visible: false, format: 'yyyy-MM-dd', dataType: 'datetime' },
    { caption: 'Work Email', dataField: 'WorkEmail', visible: false },
    { caption: 'Home Email', dataField: 'HomeEmail', visible: false },
    { caption: 'Home Phone Number', dataField: 'HomePhoneNumber', visible: false },
    { caption: 'Work Phone Number', dataField: 'WorkPhoneNumber', visible: false },
    { caption: 'Mobile Phone Number', dataField: 'MobilePhoneNumber', visible: false },
    { caption: 'School Phone Number', dataField: 'SchoolPhoneNumber', visible: false },
    { caption: 'Address Verified', dataField: 'AddressVerified', visible: false },
    { caption: 'Vaccination Verified Date', dataField: 'DateVaccinationRecordVerified', visible: false, format: 'yyyy-MM-dd', dataType: 'datetime' },
    { caption: 'Employee Id', dataField: 'EmployeeId', visible: false },
    { caption: 'EPS Id', dataField: 'EpsId', visible: false },
    { caption: 'Import Id', dataField: 'ImportId', visible: false },
    { caption: 'Record Reviewed By Member', dataField: "RecordReviewedByMember", visible: false, format: 'yyyy-MM-dd hh:mm a', dataType: 'datetime',
      calculateCellValue: function (data) {
        return convertToTZ(data.RecordReviewedByMember);
      }  
    },
    {caption: 'Last Updated', dataField: "LastUpdated", visible: false, format: 'yyyy-MM-dd hh:mm a', dataType: 'datetime',
      calculateCellValue: function (data) {
        return convertToTZ(data.LastUpdated);
      }  
    }
  ],
  defaultPopupOptions: {
    dragEnabled: true,
    closeOnOutsideClick: false,
    showTitle: true,
    showCloseButton: true,
    animation: null,
    height: 'calc(100vh - 20px)'
  },
  defaultGridOptions,
  defaultCommitteeCopyGridOptions,
  defaultAdminGridOptions: {
    ...defaultGridOptions,
    onInitNewRow: (e) => { e.data.Current = true; },
  },
  schoolWardPattern: "^[A-I]{1}$",
  schoolWardValidationMessage: 'Ward must be a letter between "A" and "I"',
  defaultSelectDateBoxOptions: {
    showClearButton: true,
    displayFormat: 'yyyy-MM-dd',
    invalidDateMessage: 'Value must be a date.',
    onKeyDown: (e) => {
      if (e.event.key === 'Enter') {
        selectDateBoxSetDate(e);
      }
    },
    onFocusOut: (e) => {
      selectDateBoxSetDate(e);
    }
  },
  defaultSelectDateTimeBoxOptions: {
    showClearButton: true,
    type: 'datetime',
    displayFormat: 'yyyy-MM-dd hh:mm a',
    invalidDateMessage: 'Value must be a date and time.',
    onKeyDown: (e) => {
      if (e.event.key === 'Enter') {
        selectDateTimeBoxSetDateTime(e);
      }
    },
    onFocusOut: (e) => {
      selectDateTimeBoxSetDateTime(e);
    },
  },
  acceptFileToUpload: ".pdf, .doc, .docx, .xls, .xlsx, .ppt, .pptx, .txt, .jpg, .jpeg, .png, .heic, .gif, .htm, .html, .msg, .mht, .eml",
  acceptImageToUpload: ".jpg, .jpeg, .png, .heic, .gif",
  defaultCommitteeMembershipGridOptions: {
    ...defaultGridOptions,
    remoteOperations: false,
    scrolling: {
      mode: "standard",
      rowRenderingMode: "standard"
    },
    paging: {
      enabled: false
    },
    pager: {
      visible: true,
      showInfo: true,
    },
  },
  defaultAttachmentGridOptions: {
    ...defaultGridOptions,
    remoteOperations: false,
    scrolling: {
      mode: "standard",
      rowRenderingMode: "standard"
    },
    export: {
      enabled: false,
    },
    pager: {
      visible: false,
    },
    filterPanel: {
      visible: false,
    },
    summary: {
      totalItems: [{
        column: "Name",
        summaryType: "count",
      }]
    }
  },
  defaultFormOptions: {
    onContentReady: (e) => {
      setFocusOnFirstInput(e.component.element());
    },
  }
};